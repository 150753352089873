a.back-to {
  position: relative;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 4px;
  border-bottom: 2px solid #008df9;
}
a.back-to:hover {
  border-bottom: 2px solid #23527c;
}
.services-section-eight {
  background-image: url(/images/custom/brand_bg.png);
  background-repeat: no-repeat;
  background-position: center;
}
.m-fixed-bar img {
  width: 50px;
}
.brand-btn {
  width: 200px;
}

.m-fixed-bar {
  display: none;
}
.floating-bar {
  position: fixed;
  bottom: 20%;
  right: 5px;
  z-index: 10;
  width: 120px;
}

.logo-box .logo {
  width: 200px;
  height: 60px;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
}
.header-style-six .logo-box .logo,
.header-style-five .logo-box .logo{
  background-image: url(/images/custom/sounder.svg);
  background-size: contain;
  background-position: center;
}

.logo-box .logo a {
  width: 100%;
  height: 100%;
  display: block;
}
.widget-content .social-icon-bar li {
  margin: 0;
  margin-right: 10px;
}
.widget-content {
  color: #fff;
}
.widget-content .address {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.widget-content a {
  color: #fff;
}
.widget-content h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.title {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: rgba(0,0,10,0.40);
  text-transform: uppercase;
}
.swiper-slide {
  background-repeat: no-repeat;
  background-size: cover;
}
.slider-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.social-icon-bar {
  display: flex;
  align-items: center;
}

.social-icon-bar li {
  width: 30px;
  height: 30px;
  display: block;
  margin: 5px;
}

.social-icon-bar a {
  width: 100%;
  height: 100%;
  display: block;
}

.social-icon-bar li.line_icon {
  background-image: url("/images/custom/line.svg");
}
.social-icon-bar li.fb_icon {
  background-image: url("/images/custom/fb.svg");
}

@media only screen and (max-width: 480px) {
  .main-header .main-box{
    padding: 0;
  }
  .services-section-eight {
    padding: 40px 0;
  }
  .blog-section, .editor {
    padding: 40px 0;
  }
  .footer-style-two.alternate {
    background: #01111c;
  }
  .services-section-eight .outer-blocks {
    margin-top: 40px;
  }
  .footer-style-two .widgets-section {
    padding: 45px 0;
  }
  .blog-section,
  .blog-single-section {
    padding: 50px 0 40px;
  }
  .logo-box .logo {
    width: 150px;
    height: 60px;
  }
  .m-fixed-bar {
    display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #0448aa;
  }
  .m-fixed-bar ul {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }
  .m-fixed-bar ul li a {
    color: #fff;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
  }
  .m-fixed-bar ul li a img {
    padding: 0 10px;
  }
  .m-fixed-bar ul li {
    color: #fff;
    display: inline-block;
    float: left;
    width: 100%;
    height: 45px;
    text-align: center;
    line-height: 45px;
    font-size: 20px;
    letter-spacing: 2px;
  }
}