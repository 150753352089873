/* Conpress Business & Agency HTML Template */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header / Style Two / Style Three / Style Four / Style Five / Style Six
5. Main Slider
6. Services Section
7. Chart Section
8. Default Section
9. Counter Section
10. Project Section
11. Call To Action Section / Style Two
12. Price Section
13. Testimonial Section / Style Two
14. Map Section
15. Newslatter Section
16. About Section / Style Two
17. Chart Section
18. Plans Section
19. Fullwidth Section
20. Team Section
21. Clients Section
22. Project Section
23. Pricing Tab Section
24. Page Title
25. Blogs Section
26. Error Section
27. Faq's Section
28. Pricing Section / Style Two / Style Three
29. Project Masonry
30. Project FullWidth / Style Two / Style Three / Style Four
31. Team Section / Style Two / Style Three / Style Four
32. Contact Map
33. Contact Info
34. Contact Form

**********************************************/

@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

/*** 

====================================================================
	Reset
====================================================================

 ***/
* {
	margin:0;
	padding:0;
	border:none;
	outline:none;
}

/*** 

====================================================================
	Global Settings
====================================================================

 ***/

body {
	font-size:18px;
	color:#777777;
	line-height:1.8em;
	font-weight:400;
	background:#ffffff;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	font-family: 'Poppins', "微軟正黑體", sans-serif;
}

a{
	text-decoration:none;
	cursor:pointer;
	color:#008df9;
}

a:hover,a:focus,a:visited{
	text-decoration:none;
	outline:none;
}

h1,h2,h3,h4,h5,h6 {
	position:relative;
	font-weight:normal;
	margin:0px;
	background:none;
	line-height:1.6em;
}

.theme_color{
	color:#008df9;
}

.blue-color{
	color:#5d59ec !important;
}

.green_color{
	color:#00d541 !important;
}

.no-padd-top{
	padding-top:0px !important;
}

.separator{
	position:relative;
	width:100%;
	height:1px;
	background-color:#f2f2f2;
}

p{
	position:relative;
	line-height:1.8em;	
}

.strike-through{
	text-decoration:line-through;	
}

.auto-container{
	position:static;
	max-width:1200px;
	padding:0px 15px;
	margin:0 auto;
}

.medium-container{
	max-width:850px;
}

.page-wrapper{
	position:relative;
	margin:0 auto;
	width:100%;
	min-width:300px;
}

.grey-bg{
	background-color:#f8fcff;
}

ul,li{
	list-style:none;
	padding:0px;
	margin:0px;	
}

.theme-btn{
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.centered{
	text-align:center;	
}

/*List Style One*/

.list-style-one{
	position:relative;
	margin:0px 0px;
}

.list-style-one li{
	position:relative;
	padding-left:30px;
	margin-bottom:8px;
	color:#000000;
	font-size:15px;
	font-weight:400;
}

.list-style-one li:before{
	position:absolute;
	content:'\f00c';
	left:0px;
	top:0px;
	color:#43cb83;
	font-size:16px;
	font-weight:300;
	font-family: 'FontAwesome';
}

.list-style-one li.cross:before{
	position:absolute;
	content:'\f00d';
	left:0px;
	top:0px;
	color:#e21e1e;
	font-size:16px;
	font-weight:300;
	font-family: 'FontAwesome';
}

/*Btn Style One*/

.btn-style-one{
	position:relative;
	line-height:24px;
	color:#222222;
	font-size:15px;
	font-weight:600;
	background:none;
	display:inline-block;
	padding:12px 40px 12px;
	border:2px solid #e7e7e7;
	border-radius:50px;
	text-transform:uppercase;
}

.btn-style-one:hover{
	opacity:0.70;
	color:#ffffff;
	border-color:#03c7d9;
	background-color:#03c7d9;
}

/*Btn Style Two*/

.btn-style-two{
	position:relative;
	line-height:24px;
	color:#ffffff;
	font-size:15px;
	font-weight:500;
	background:#03c7d9;
	display:inline-block;
	padding:13px 40px 13px;
	border-radius:50px;
	text-transform:uppercase;
}

.btn-style-two:hover{
	background:#ff4052;
	color:#ffffff;
}

/*Btn Style Three*/

.btn-style-three{
	position:relative;
	line-height:24px;
	color:#ffffff;
	font-size:14px;
	font-weight:500;
	background:none;
	border-radius:50px;
	letter-spacing:1px;
	display:inline-block;
	padding:11px 38px 11px;
	text-transform:uppercase;
	border:2px solid #ffffff;
}

.btn-style-three:hover{
	background:#03c7d9;
	color:#ffffff;
	border-color:#03c7d9;
}

/*Btn Style Four*/

.btn-style-four{
	position:relative;
	line-height:24px;
	color:#03c7d9;
	font-size:13px;
	font-weight:500;
	background:none;
	display:inline-block;
	padding:11px 34px 11px;
	border:2px solid #03c7d9;
	border-radius:50px;
	text-transform:uppercase;
}

.btn-style-four:hover{
	color:#ffffff;
	background-color:#03c7d9;
}

/*Btn Style Five*/

.btn-style-five{
	position:relative;
	line-height:24px;
	color:#ffffff;
	font-size:15px;
	font-weight:600;
	background:none;
	letter-spacing:1px;
	display:inline-block;
	padding:11px 38px 11px;
	text-transform:uppercase;
	border:2px solid #ffffff;
}

.btn-style-five:hover{
	background:#03c7d9;
	color:#ffffff;
	border-color:#03c7d9;
}

/*Btn Style Six*/

.btn-style-six{
	position:relative;
	line-height:24px;
	color:#ffffff;
	font-size:15px;
	font-weight:600;
	background:#ff5151;
	display:inline-block;
	padding:9px 31px 9px;
	text-transform:uppercase;
	border:2px solid #ff5151;
}

.btn-style-six:hover{
	background:none;
	color:#ff5151;
	border-color:#ff5151;
}

/*Btn Style Seven*/

.btn-style-seven{
	position:relative;
	line-height:24px;
	color:#00000a;
	font-size:15px;
	font-weight:600;
	background:#f3f3f3;
	letter-spacing:1px;
	display:inline-block;
	padding:13px 34px 13px;
	text-transform:uppercase;
}

.btn-style-seven:hover{
	background:#03c7d9;
	color:#ffffff;
}

/*Btn Style Eight*/

.btn-style-eight{
	position:relative;
	line-height:24px;
	color:#ffffff;
	font-size:15px;
	font-weight:600;
	background:#5d59ec;
	letter-spacing:1px;
	display:inline-block;
	padding:28px 34px 28px;
	text-transform:uppercase;
}

.btn-style-eight:hover{
	opacity:0.9;
	color:#ffffff;
}

/*Btn Style Nine*/

.btn-style-nine{
	position:relative;
	line-height:24px;
	color:#ffffff;
	font-size:15px;
	font-weight:600;
	background:#5d59ec;
	letter-spacing:1px;
	display:inline-block;
	padding:13px 34px 13px;
	text-transform:uppercase;
}

.btn-style-nine:hover{
	opacity:0.9;
	color:#ffffff;
}

/*Btn Style Ten*/

.btn-style-ten{
	position:relative;
	line-height:24px;
	color:#ffffff;
	font-size:15px;
	font-weight:600;
	background:#008df9;
	letter-spacing:1px;
	display:inline-block;
	padding:33px 34px 33px;
	text-transform:uppercase;
}

.btn-style-ten:hover{
	opacity:0.9;
	color:#ffffff;
}

/*Btn Style Eleven*/

.btn-style-eleven{
	position:relative;
	line-height:24px;
	color:#ffffff;
	font-size:15px;
	font-weight:600;
	background:#00d541;
	letter-spacing:1px;
	display:inline-block;
	padding:11px 38px 11px;
	text-transform:uppercase;
	border:2px solid #00d541;
}

.btn-style-eleven:hover{
	background:#03c7d9;
	color:#ffffff;
	border-color:#03c7d9;
}

/*Btn Style Twelve*/

.btn-style-twelve{
	position:relative;
	line-height:24px;
	color:#ffffff;
	font-size:15px;
	font-weight:600;
	background:#00d541;
	letter-spacing:1px;
	display:inline-block;
	padding:13px 34px 13px;
	text-transform:uppercase;
}

.btn-style-twelve:hover{
	opacity:0.9;
	color:#ffffff;
}

.preloader{ position:fixed; left:0px; top:0px; width:100%; height:100%; z-index:999999; background-color:#ffffff; background-position:center center; background-repeat:no-repeat; background-image:url(/images/icons/preloader.svg);}

img{
	display:inline-block;
	max-width:100%;
	height:auto;	
}

/*** 

====================================================================
	Fancy Box
====================================================================

***/

.fancybox-next span,
.fancybox-prev span{
	background-image:none !important;
	width:44px !important;
	height:44px !important;
	line-height:44px !important;
	text-align:center;
}

.fancybox-next span:before,
.fancybox-prev span:before{
	content:'';
	position:absolute;
	font-family: 'FontAwesome';
	left:0px;
	top:0px;
	font-size:12px;
	width:44px !important;
	height:44px !important;
	line-height:44px !important;
	background-color:rgba(28,28,28,0.40) !important;
	color:#ffffff;
	visibility:visible;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.fancybox-next span:before{
	content:'\f178';
}

.fancybox-prev span:before{
	content:'\f177';
}

.fancybox-next:hover span:before,
.fancybox-prev:hover span:before{
	background-color:#ffffff !important;
	color:#000000;	
}

.fancybox-type-image .fancybox-close{
	right:0px;
	top:0px;
	width:45px;
	height:45px;
	background:url(/images/icons/icon-cross.png) center center no-repeat;
	background-color:rgba(17,17,17,0.50) !important;	
}

.fancybox-type-image .fancybox-close:hover{
	background-color:#000000 !important;	
}

.fancybox-type-image .fancybox-skin{
	padding:0px !important;	
}

/*Custom Select*/

.form-group .ui-selectmenu-button.ui-button{
	width:100%;
	font-size:14px;
	font-style:normal;
	height:50px;
	padding:10px 20px;
	line-height:30px;
	color:#333333;
	border-radius:0px;
	border:1px solid #e5e5e5;
	background-color:#ffffff;
}

.form-group .ui-button .ui-icon{
	background:none;
	position:relative;
	top:3px;
	text-indent:0px;
	color:#333333;	
}

.form-group .ui-button .ui-icon:before{
	font-family: 'FontAwesome';
	content: "\f107";
	position:absolute;
	right:0px;
	top:2px !important;
	top:13px;
	width:10px;
	height:20px;
	display:block;
	color:#e2e2e2;
	line-height:20px;
	font-size:16px;
	font-weight:normal;
	text-align:center;
	z-index:5;
}

.ui-widget.ui-widget-content{
	border:1px solid #e0e0e0;
}

.ui-menu .ui-menu-item{
	font-size:14px;
	border-bottom:1px solid #e0e0e0;
}

.ui-menu .ui-menu-item:last-child{
	border:none;	
}

.ui-state-active, .ui-widget-content .ui-state-active{
	background-color:#8b755a;
}

.ui-menu .ui-menu-item-wrapper{
	position:relative;
	display:block;
	padding:8px 20px ;
	font-size:14px;
	line-height:24px;
}

.ui-menu-item:hover{
	background-color:#8b755a;
}

/*** 

====================================================================
	Social Icon One
====================================================================

***/

.social-icon-one{
	position:relative;
}

.social-icon-one .share{
	color:#222222;
	font-size:16px;
	font-weight:700;
}

.social-icon-one li{
	position:relative;
	margin-left:12px;
	display:inline-block;
}

.social-icon-one li a{
	position:relative;
	font-size:18px;
	color:#333333;
	display:inline-block;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.social-icon-one li a:hover{
	color:#03c7d9;
}

/*** 

====================================================================
	Social Icon Two
====================================================================

***/

.social-icon-two{
	position:relative;
}

.social-icon-two .share{
	color:#222222;
	font-size:16px;
	font-weight:700;
}

.social-icon-two li{
	position:relative;
	margin:0px 6px;
	display:inline-block;
}

.social-icon-two li a{
	position:relative;
	font-size:18px;
	color:#b7b7b7;
	display:inline-block;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.social-icon-two li a:hover{
	color:#333333;
}

/*Social Icon Three*/

.social-icon-three{
	position:relative;
}

.social-icon-three .share{
	position:relative;
	color:#151515;
	font-size:16px;
	font-weight:500;
	display:inline-block;
}

.social-icon-three a{
	position:relative;
	color:#dbdbdb;
	font-size:16px;
	text-align:center;
	margin-left:10px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
}

.social-icon-three a:hover{
	color:#080808;
}

/*** 

====================================================================
	Social Icon Four
====================================================================

***/

.social-icon-four{
	position:relative;
	margin-top:30px;
}

.social-icon-four li{
	position:relative;
	margin-right:6px;
	display:inline-block;
}

.social-icon-four li a{
	position:relative;
	width:40px;
	height:40px;
	font-size:18px;
	text-align:center;
	border-radius:50%;
	line-height:40px;
	color:#d8d8d8;
	border:1px solid #d8d8d8;
	display:inline-block;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.social-icon-four li a:hover{
	color:#00000a;
	border-color:#00000a;
}

/*** 

====================================================================
	Scroll To Top style
====================================================================

***/

.scroll-to-top{
	position:fixed;
	bottom:55px;
	right:15px;
	width:50px;
	height:50px;
	color:#ffffff;
	font-size:13px;
	text-transform:uppercase;
	line-height:50px;
	text-align:center;
	z-index:100;
	cursor:pointer;
	background:#444444;
	display:none;
	border-radius:3px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;		
}

.scroll-to-top:hover{
	color:#ffffff;
	background:#113c6c;
}

/*** 

====================================================================
	Main Header style
====================================================================

***/

.main-header{
	position:relative;
	left:0px;
	top:0px;
	z-index:999;
	width:100%;
}

.main-header.sticked-header{
	position:fixed;
}

.main-header .auto-container{
	position:relative;	
}

.fixed-header.sticky-header .main-box .logo-box{
	padding:6px 0px;
}

/*Main Box*/

.main-header .main-box{
	position:relative;
	padding:20px 0px;
	left:0px;
	top:0px;
	width:100%;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header.fixed-header .main-box{
	position:relative;
	border-bottom:1px solid #f9f9f9;
	padding:0px 0px;
	z-index:999;
	opacity:1;
	background-color:#ffffff;
	visibility:visible;
	-ms-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-o-animation-name: fadeInDown;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	-ms-animation-duration: 500ms;
	-moz-animation-duration: 500ms;
	-o-animation-duration: 500ms;
	-webkit-animation-duration: 500ms;
	animation-duration: 500ms;
	-ms-animation-timing-function: linear;
	-moz-animation-timing-function: linear;
	-o-animation-timing-function: linear;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-ms-animation-iteration-count: 1;
	-moz-animation-iteration-count: 1;
	-o-animation-iteration-count: 1;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;	
}

.header-style-one.fixed-header .main-box{
	-ms-animation:none;
	-moz-animation: none;
	-o-animation: none;
	-webkit-animation: none;
	animation: none;
}

.main-header .main-box .outer-container{
	display: flex;
	align-items: center;
	justify-content: space-between;
}


.main-header .main-box .logo-box .logo img{
	display:inline-block;
	max-width:100%;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
}

.main-header .nav-outer{
	display: flex;
	align-items: center;
}

.main-header .nav-outer .outer-box{
	margin-left:30px;
}

.main-header .nav-outer .search-box-outer{
	position: relative;
	margin-right:15px;
	display:inline-block;
}

.main-header .nav-outer .search-box-btn{
	position:relative;
	display:block;
	background:none;
	font-size:16px;
	color:#ffffff;
	line-height:38px;
	cursor:pointer;
	text-align:center;
	border-radius:50%;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .nav-outer .search-box-outer .dropdown-menu{
	top:65px;
	right:0px;
	padding:0px;
	width:280px;	
	border-radius:0px;
	border-top:2px solid #121212;
}

.main-header .nav-outer .search-box-outer .dropdown-menu > li{
	padding:0px;
	border:none;
	background:none;
}

.main-header .nav-outer .search-panel .form-container{
	padding:25px 20px;	
}

.main-header .nav-outer .search-panel .form-group{
	position:relative;
	margin:0px;	
}

.main-header .nav-outer .search-panel input[type="text"],
.main-header .nav-outer .search-panel input[type="search"],
.main-header .nav-outer .search-panel input[type="password"],
.main-header .nav-outer .search-panel select{
	display:block;
	width:100%;
	height:40px;
	line-height:24px;
	background:#ffffff;
	padding:7px 40px 7px 15px;
	border:1px solid #e0e0e0;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .nav-outer .search-panel input:focus,
.main-header .nav-outer .search-panel select:focus{
	border-color:#ff4052;	
}

.main-header .nav-outer .search-panel .search-btn{
	position:absolute;
	right:0px;
	top:0px;
	width:40px;
	height:40px;
	text-align:center;
	color:#555555;
	font-size:12px;
	background:none;
	cursor:pointer;
	line-height:40px;
}

/*Header Style Five*/

.main-header .language .dropdown-menu{
	width:180px;
	padding:0px;
	margin-top:30px;
	border-radius:0px;
	background-color:#222222;
    border-top:2px solid #ffffff;
}

.main-header .language{
    line-height: 1.4em;
	display:inline-block;
}

.main-header .language a{
	color:#ffffff !important;
	border:0px;
	padding:0px;
	font-size:16px;
	font-weight:600;
	background:none !important;
}

.main-header .language a:hover{
	background:none;
}

.main-header .language .dropdown-menu > li{
	padding:0px;
	margin:0px;
	float:none;
	display:block !important;
    border-bottom: 1px solid rgba(255,255,255,0.20);
}

.main-header .language .dropdown-menu > li > a{
	padding:10px 20px !important;
	color:#ffffff !important;
	text-align:left;
	font-weight:400;
	font-size:14px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
}

.main-header .language .dropdown-menu > li > a:focus{
	background:none;
}

.main-header .language .dropdown-menu > li > a:hover{
	color:#ffffff !important;
	background-color:#008df9 !important;
}

/*.header-style-six .main-menu,*/
/*.header-style-five .main-menu{*/
/*	float:left;*/
/*}*/

/*Header Style Six*/

.header-style-six {
	background-color: #01111c;
	border-bottom: 1px solid #008df9;
}
.header-style-six .main-menu .navigation > li > a {
	color: #fff
}
.header-style-six .nav-outer .search-box-btn{
	color:#010101 !important;
}

.header-style-six .language a{
	color:#010101 !important;
	font-size:16px;
}

.nav-outer .outer-box{
	display: flex;
	align-items: center;
}

.header-style-six .language .dropdown-menu{
	right:0px;
	left:auto;
}


.main-menu{
	position:relative;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-menu .navbar-collapse{
	padding:0px;	
}

.main-menu .navigation{
	position:relative;
	margin:0px;
}

.main-menu .navigation > li{
	position:relative;
	float:left;
	padding: 0;
	margin-left:30px;
}

.main-menu .navigation > li > a{
	position:relative;
	display:block;
	padding:20px 0px 20px;
	color:#2f2f2f;
	text-align:center;
	line-height:30px;
	letter-spacing:0px;
	font-weight:bold;
	font-size:23px;
	opacity:1;
	text-transform:uppercase;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.sticky-header .main-menu .navigation > li > a{
	font-size:15px !important;	
}

.sticky-header .main-menu .navigation > li{
	margin:0px 0px 0px 25px !important;
}

.main-menu .navigation > li:hover > a,
.main-menu .navigation > li.current > a{
	color:#03c7d9 !important;
	opacity:1;
}

.header-style-three .main-menu .navigation > li:hover > a,
.header-style-three .main-menu .navigation > li.current > a,
.header-style-five .main-menu .navigation > li:hover > a,
.header-style-five .main-menu .navigation > li.current > a{
	color:#008df9 !important;
}

.header-style-four .main-menu .navigation > li:hover > a,
.header-style-four .main-menu .navigation > li.current > a{
	color:#8075f2 !important;
}

.header-style-six .main-menu .navigation > li:hover > a,
.header-style-six .main-menu .navigation > li.current > a{
	color:#008df9 !important;
}

.main-menu .navigation > li:hover > a:before,
.main-menu .navigation > li.current > a:before{
	width:100%;
}

.main-menu .navigation > li.dropdown > a{
	padding-right:18px;	
}

.main-menu .navigation > li.dropdown > a:after{
	font-family: 'FontAwesome';
	content: "\f107";
	position:absolute;
	right:0px;
	top:50%;
	margin-top:-9px;
	height:20px;
	display:block;
	line-height:20px;
	font-size:15px;
	font-weight:normal;
	z-index:5;	
}

.main-menu .navigation > li > ul{
	position:absolute;
	left:0px;
	top:100%;
	width:220px;
	z-index:100;
	display:none;
	padding:0px 0px;
	background:#101010;
	border-top:2px solid #03c7d9;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
}

.main-menu .navigation > li:last-child > ul{
	left:auto;
	right:0px;	
}

.main-menu .navigation > li > ul > li{
	position:relative;
	width:100%;
	border-bottom:1px solid rgba(255,255,255,0.10);
}

.main-menu .navigation > li > ul > li:last-child{
	border-bottom:none;	
}

.main-menu .navigation > li > ul > li > a{
	position:relative;
	display:block;
	padding:12px 20px;
	line-height:22px;
	font-weight:400;
	font-size:14px;
	color:#ffffff;
	text-align:left;
	text-transform:capitalize;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul > li:hover > a{
	color:#ffffff;
	background-color:#03c7d9;
}

.header-style-three .main-menu .navigation > li > ul > li:hover > a,
.header-style-five .main-menu .navigation > li > ul > li:hover > a{
	background-color:#ff5151;
}

.header-style-four .main-menu .navigation > li > ul > li:hover > a{
	background-color:#8075f2;
}

.header-style-six .main-menu .navigation > li > ul > li:hover > a{
	background-color:#00d541;
}

.main-menu .navigation > li > ul > li.dropdown > a:after{
	font-family: 'FontAwesome';
	content: "\f105";
	position:absolute;
	right:10px;
	top:12px;
	width:10px;
	height:20px;
	display:block;
	line-height:21px;
	font-size:16px;
	font-weight:normal;
	text-align:center;
	z-index:5;	
}

.main-menu .navigation > li > ul > li.dropdown:hover > a:after{
	color:#ffffff;
}

.main-menu .navigation > li > ul > li > ul{
	position:absolute;
	left:100%;
	top:0px;
	width:220px;
	z-index:100;
	display:none;
	background:#101010;
	border-top:2px solid #03c7d9;
	-webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
}

.header-style-three .main-menu .navigation > li > ul,
.header-style-three .main-menu .navigation > li > ul > li > ul,
.header-style-five .main-menu .navigation > li > ul,
.header-style-five .main-menu .navigation > li > ul > li > ul{
	border-top-color:#ff5151;	
}

.header-style-four .main-menu .navigation > li > ul,
.header-style-four .main-menu .navigation > li > ul > li > ul{
	border-top-color:#8075f2;	
}

.header-style-six .main-menu .navigation > li > ul,
.header-style-six .main-menu .navigation > li > ul > li > ul{
	border-top-color:#00d541;	
}

.main-menu .navigation > li > ul > li > ul > li{
	position:relative;
	width:100%;
	border-bottom:1px solid rgba(255,255,255,0.10);
}

.main-menu .navigation > li > ul > li > ul > li:last-child{
	border-bottom:none;	
}

.main-menu .navigation > li > ul > li  > ul > li > a{
	position:relative;
	display:block;
	padding:12px 20px;
	line-height:22px;
	font-weight:400;
	font-size:14px;
	color:#ffffff;
	text-align:left;
	text-transform:capitalize;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul > li  > ul > li > a:hover{
	color:#ffffff;
	background-color:#03c7d9;
}

.header-style-three .main-menu .navigation > li > ul > li  > ul > li > a:hover,
.header-style-five .main-menu .navigation > li > ul > li  > ul > li > a:hover{
	background-color:#ff5151;
}

.header-style-four .main-menu .navigation > li > ul > li  > ul > li > a:hover{
	background-color:#8075f2;
}

.header-style-six .main-menu .navigation > li > ul > li  > ul > li > a:hover{
	background-color:#00d541;
}

.main-menu .navigation > li.dropdown:hover > ul{
	visibility:visible;
	opacity:1;
	top:100%;	
}

.main-menu .navigation li > ul > li.dropdown:hover > ul{
	visibility:visible;
	opacity:1;
	top:0px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;	
}

.main-menu .navigation li.dropdown .dropdown-btn{
	position:absolute;
	right:10px;
	top:6px;
	width:34px;
	height:30px;
	border:1px solid #ffffff;
	text-align:center;
	font-size:16px;
	line-height:28px;
	color:#ffffff;
	cursor:pointer;
	z-index:5;
	display:none;
}

.main-header .btn-outer{
	position:relative;
	float:right;
	padding:24px 0px;
	margin-left:30px;
}

.header-style-two{
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 999;
    width: 100%;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.main-header .header-upper{
	position:relative;
	padding:0px 0px;
	z-index:5;
	overflow:hidden;
	background:#ffffff;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	border-bottom:1px solid #f1f1f1;
}

.header-style-two .header-upper .logo img{
	position:relative;
	display:inline-block;
	max-width:100%;
}

.header-style-two .logo-outer{
	position:relative;
	z-index:25;
	padding:33px 0px;
}

.header-style-two .logo-outer .logo img{
	position:relative;
	display:inline-block;
	max-width:100%;
}

.header-style-two .header-upper .upper-right{
	position:relative;
	padding:0px 0px 0px;
}

.header-style-two .info-box{
	position:relative;
	float:left;
	min-height:52px;
	font-size:13px;
	color:#777777;
	text-transform:uppercase;
	padding:38px 20px 40px 30px;
}

.header-style-two .info-box .inner-box{
	position:relative;
	padding-left:50px;
}

.header-style-two .info-box .icon-box{
	position:absolute;
	left:0px;
	top:6px;
	width:52px;
	height:40px;
	color:#04cae7;
	font-size:36px;
	text-align:left;
	line-height:40px;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition:all 300ms ease;
}

.header-style-two .info-box:last-child{
	padding-right:0px;
}

.header-style-two .info-box li{
	position:relative;
	font-weight:400;
	color:#7b7b7b;
	font-size:18px;
	margin-bottom:2px;
	text-transform:capitalize;
}

.header-style-two .info-box li a{
	color:#777777;		
}

.header-style-two .info-box strong{
	position:relative;
	line-height:24px;
	color:#00000a;
	font-size:18px;
	font-weight:600;
	text-transform:capitalize;
}

.header-style-two .header-lower{
	position:relative;
	top:0px;
	z-index:5;
	background:rgba(255,255,255,1);
}

.header-style-two .nav-outer{
	float:left;
	width:100%;
}

.header-style-two .main-menu .navigation > li{
	padding:0px;
	margin-left:0px;
	padding-right:24px;
	margin-right:25px;
}

.header-style-two .main-menu .navigation > li:after{
	position:absolute;
	content:'';
	right:0px;
	top:50%;
	width:1px;
	height:20px;
	margin-top:-10px;
	background-color:#e8e8e8;
}

.header-style-two .main-menu .navigation > li:last-child{
	padding-right:0px;
	margin-right:0px;
}

.header-style-two .main-menu .navigation > li:last-child::after{
	display:none;
}

/*Search form*/

.header-style-two .nav-outer .search-form{
	position:absolute;
	right:0px;
	top:-1px;
	width:300px;
}

.header-style-two .search-form .form-group{
	position:relative;
	display:block;
	margin:0px;
	border-left:1px solid #f1f1f1;
}

.header-style-two .search-form .form-group input[type="text"]{
	position:relative;
	display:block;
	width:100%;
	color:#000000;
	font-size:16px;
	background:none;
	height:70px;
	padding:10px 50px 10px 25px;
	border-right:1px solid #f1f1f1;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
}

.header-style-two .search-form .form-group input::-webkit-input-placeholder{
  color:rgba(0,0,0,0.60);
}

.header-style-two .search-form .form-group input[type="submit"],
.header-style-two .search-form button{
	position:absolute;
	right:0px;
	top:1px;
	width:70px;
	height:70px;
	font-size:16px;
	line-height:70px;
	color:#ffffff;
	font-weight:400;
	background:#212121;
	z-index:12;
}

.main-header .sticky-header{
    position: fixed;
    opacity: 0;
    visibility: hidden;
    left: 0px;
    top: 0px;
    width: 100%;
    padding: 0px 0px;
    background: #ffffff;
    z-index: 0;
    border-bottom: 1px solid #e0e0e0;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.header-style-two .sticky-header li{
	padding-right:0px !important;
}

.header-style-two .sticky-header li:after{
	display:none;
}

.header-style-three.fixed-header .main-menu .navigation > li{
	padding:0px;
}

.header-style-three.fixed-header .main-menu .navigation > li > a{
	color:#2f2f2f;
}

.header-style-three .main-menu .navigation > li > a{
	color:#ffffff;
	font-weight:500;
	font-size:18px;
	text-transform:capitalize;
}

.sticky-header .logo{
	padding:10px 0px 10px;
}

.fixed-header .sticky-header{
	z-index:999;
	opacity:1;
	visibility:visible;
	-ms-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-op-animation-name: fadeInDown;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	-ms-animation-duration: 500ms;
	-moz-animation-duration: 500ms;
	-op-animation-duration: 500ms;
	-webkit-animation-duration: 500ms;
	animation-duration: 500ms;
	-ms-animation-timing-function: linear;
	-moz-animation-timing-function: linear;
	-op-animation-timing-function: linear;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-ms-animation-iteration-count: 1;
	-moz-animation-iteration-count: 1;
	-op-animation-iteration-count: 1;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;	
}

.header-style-three{
	position:absolute;
	left:0px;
	top:0px;
}

/*Header Style Four*/

.header-style-four{
	position:absolute;
}

.header-style-four .main-box .logo-box{
	padding:0px;
}

.header-style-four .main-box .outer-container{
	background-color:#ffffff;
	padding:0px 0px 0px 30px;
}

.header-style-four .main-box .outer-container:before{
	position:absolute;
	content:'';
	left:20px;
	top:0px;
	right:20px;
	bottom:-20px;
	height:100px;
	display:block;
	background-color:rgba(255,255,255,0.90);
}

.header-style-four .main-menu .navigation > li{
	padding:5px 0px;
	margin-left:20px;
}

.header-style-four.fixed-header .main-menu .navigation > li{
	padding:0px;
}

.header-style-four .main-menu .navigation > li > a{
	font-size:18px;
	font-weight:500;
	text-transform:capitalize;
}

.header-style-four .btn-outer{
	padding:0px 0px;
}

.header-style-four .header-top{
	position:relative;
	padding-top:35px;
	padding-bottom:30px;
}

.header-style-four .header-top .help{
	position:relative;
	color:#ffffff;
	font-size:17px;
	font-weight:500;
	padding-bottom:1px;
	margin-bottom:10px;
	display:inline-block;
	border-bottom:2px solid rgba(255,255,255,0.50);
}

.header-style-four .header-top .contact{
	position:relative;
	color:#ffffff;
	font-weight:500;
	font-size:17px;
	display:inline-block;
}

.header-style-four .header-top .contact span{
	font-weight:400;
	font-size:24px;
	margin-left:50px;
}

.header-style-four .header-top .social-icon-two{
	display:inline-block;
	margin-left:40px;
}

.header-style-four .header-top .social-icon-two li a:hover{
	color:#ffffff;
}

/*Header Style Five*/

.header-style-five{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
}


.header-style-five .btn-outer{
	padding:0px;
}


.header-style-five .main-menu .navigation > li > a{
	color:#ffffff;
}

.header-style-five.fixed-header .main-menu .navigation > li > a{
	color:#2f2f2f;
}

.header-style-five.fixed-header .main-menu .navigation > li,
.header-style-six.fixed-header .main-menu .navigation > li{
	padding:0px;
}

.header-style-six .nav-outer .outer-box .nav-toggler{
	position:relative;
	font-size:20px;
	font-weight:600;
	top:2px;
	cursor:pointer;
	color:#505071;
	margin-left:20px;
	display:inline-block;
}

.contact-page-header{
	background-color:rgba(0,0,0,0.50);
}

/*** 

====================================================================
	Main Slider style
====================================================================

***/

.main-slider{
	position:relative;
	z-index:10;
	overflow:hidden;
}

.main-slider .tp-caption{
	z-index:5 !important;
}

.main-slider .tp-dottedoverlay{
	background:none !important;	
}

.slider-content h2{
	position:relative;
	color:#2a2a2a;
	font-size:65px;
	font-weight:300;
	line-height:1.2em;
	padding-bottom:20px;
}

.slider-content h2.bold{
	font-weight:800;
	font-size:70px;
}

.slider-content h3{
	position:relative;
	color:#ffffff;
	font-size:62px;
	line-height:1.2em;
	font-weight:700;
}

.slider-content h4{
	position:relative;
	color:#ffffff;
	font-size:60px;
	font-weight:600;
	line-height:1.2em;
	padding-bottom:20px;
}

.slider-content h4.bold{
	color:#00102d;
	font-size:60px;
	line-height:1.1em;
	font-weight:700;
}

.slider-content .text{
	position:relative;
	font-size:25px;
	font-weight:400;
	line-height:1.6em;
	color:rgba(0,0,0,0.60);
}

.slider-content .text.white{
	color:#ffffff;
}

.slider-content .text.dark{
	color:#00102d;
}

.slider-content .tp-bannertimer,
.slider-content .tp-bullets{
	display:none !important;	
}

.slider-content.style-two:after{
	position:absolute;
	content:'';
	left: 0px;
    bottom: -82px;
    width: 200%;
	height:250px;
	background-color:#ffffff;
	-ms-transform: rotate(-7deg);
    -webkit-transform: rotate(-7deg);
    transform: rotate(-7deg);
}

.slider-content.style-three.alternate .section-count{
	bottom:0px;
	top:50%;
	right:0px;
	z-index:1;
	margin-top:100px;
	-ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: left top;
}

.slider-content.style-three .section-count{
	position: absolute;
    right: 100px;
    bottom: 100px;
}

.slider-content.style-three .section-count .video-box{
	position: relative;
	font-size:18px;
	color:#ffffff;
	font-weight:400;
	padding-left:100px;
	display:inline-block;
}

.slider-content.style-three .section-count .video-box span{
	font-weight:600;
}

.slider-content.style-three .section-count .video-box .icon{
	position:absolute;
	left:0px;
	width:60px;
	height:60px;
	color:#ffffff;
	font-size:24px;
	line-height:60px;
	text-align:center;
	display:inline-block;
	border-radius:50%;
	padding-left:4px;
	background-color:rgba(255,255,255,0.40);
}

.main-slider.style-three .section-count .video-box .icon:after{
	position:absolute;
	content:'';
	left:-12px;
	top:-12px;
	right:-12px;
	bottom:-12px;
	border-radius:50%;
	background-color:rgba(255,255,255,0.20);
}

/*** 

====================================================================
	Section Title
====================================================================

***/

.sec-title{
	position:relative;
	margin-bottom:65px;
}

.sec-title h2{
	position:relative;
	font-size:38px;
	font-weight:600;
	color:#00000a;
	line-height:1.2em;
	text-transform:capitalize;
}

.sec-title .text{
	position:relative;
	font-size:16px;
	margin-top:25px !important;
	color:rgba(0,0,0,0.60);
}

.sec-title.centered{
	text-align:center;
}

.sec-title.centered .text{
	max-width:790px;
	margin:0 auto;
}

.sec-title.style-two h2{
	font-weight:800;
	font-size:45px;
}

.sec-title.light h2{
	color:#ffffff;
}

.sec-title .title{
	position:relative;
	color:#a8acb9;
	font-size:14px;
	margin-bottom:5px;
	letter-spacing:1px;
	text-transform:uppercase;
}

/*** 

====================================================================
	Section Title Two
====================================================================

***/

.sec-title-two{
	position:relative;
	margin-bottom:60px;
}

.sec-title-two .title{
	position:relative;
	font-size:16px;
	font-weight:600;
	padding-bottom:5px;
	margin-bottom:25px;
	color:rgba(0,0,0,0.40);
	text-transform:uppercase;
}

.sec-title-two .title:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	width:50px;
	height:3px;
	background-color:#ff4052;
}

.sec-title-two h2{
	position:relative;
	color:#00000a;
	font-size:38px;
	font-weight:500;
	line-height:1.4em;
}

/*** 

====================================================================
	Section Title Three
====================================================================

***/

.sec-title-three{
	position:relative;
	margin-bottom:50px;
}

.sec-title-three .title{
	position:relative;
	font-size:16px;
	font-weight:600;
	margin-bottom:10px;
	color:rgba(0,0,10,0.40);
	text-transform:uppercase;
}

.sec-title-three h2{
	position:relative;
	color:#00000a;
	font-size:38px;
	font-weight:600;
	line-height:1.4em;
	padding-bottom:15px;
}

.sec-title-three h2:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	width:50px;
	height:3px;
	background-color:#5044c6;
}

.sec-title-three.centered h2:after{
	left:50%;
	margin-left:-25px;
}

.sec-title-three.light .title{
	color:rgba(255,255,255,0.50);
}

.sec-title-three.light h2{
	color:#ffffff;
}

.sec-title-three.light h2:after{
	background-color:#ffffff;
}

/*** 

====================================================================
	Section Title Four
====================================================================

***/

.sec-title-four{
	position:relative;
	margin-bottom:50px;
}

.sec-title-four .title{
	position:relative;
	font-size:16px;
	font-weight:600;
	margin-bottom:10px;
	color:rgba(0,0,10,0.40);
	text-transform:uppercase;
}

.sec-title-four h2{
	position:relative;
	color:#00000a;
	font-size:30px;
	font-weight:600;
	line-height:1.4em;
	padding-bottom:15px;
}

.sec-title-four h2:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	width:50px;
	height:3px;
	background-color:#008df9;
}

.sec-title-four .text{
	position:relative;
	font-size:16px;
	color:rgba(0,0,10,0.65);
}

.sec-title-four.centered h2:after{
	left:50%;
	margin-left:-25px;
}

.sec-title-four.light .title{
	color:rgba(255,255,255,0.40);
}

.sec-title-four.light h2{
	color:#ffffff;
}

/*** 

====================================================================
	Section Title Five
====================================================================

***/

.sec-title-five{
	position:relative;
	margin-bottom:50px;
}

.sec-title-five .title{
	position:relative;
	font-size:16px;
	font-weight:600;
	margin-bottom:10px;
	color:rgba(0,0,10,0.40);
	text-transform:uppercase;
}

.sec-title-five h2{
	position:relative;
	color:#00000a;
	font-size:30px;
	font-weight:600;
	line-height:1.4em;
	padding-bottom:20px;
}

.sec-title-five h2:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	width:50px;
	height:3px;
	background-color:#008df9;
}


/*** 

====================================================================
	Section Title
====================================================================

***/

.services-section{
	position:relative;
	padding:80px 0px 80px;
	background:url(/images/resource/pattern-1.png) center top no-repeat;
}

/*Services Block*/

.services-block{
	position:relative;
	margin-bottom:40px;
}

.services-block .inner-box{
	position:relative;
}

.services-block .inner-box .image{
	position:relative;
}

.services-block .inner-box .image a img{
	position:relative;
	width:100%;
	display:block;
	border-radius:5px;
}

.services-block .inner-box .lower-content{
	position:relative;
	padding:30px 0px 0px;
	background-color:#ffffff;
}

.services-block .inner-box .lower-content h3{
	position:relative;
	font-size:20px;
	font-weight:500;
	text-transform:capitalize;
}

.services-block .inner-box .lower-content h3 a{
	color:#00000a;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.services-block .inner-box .lower-content h3 a:hover{
	color:#ff4052;
}

.services-block .inner-box .lower-content .text{
	position:relative;
	font-size:16px;
	font-weight:400;
	color:rgba(0,0,10, 0.60);
}

.services-block .inner-box .lower-content .arrow-box{
	position:absolute;
	right:0px;
	top:40px;
	width:40px;
	height:40px;
	color:#ffffff;
	line-height:36px;
	text-align:center;
	display:inline-block;
	border-radius:50%;
	font-size:30px;
	padding-left:4px;
	opacity:0;
	background-color:#03c7d9;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.services-block .inner-box:hover .lower-content .arrow-box{
	opacity:1;
}

.services-block .inner-box .lower-content .arrow-box:hover{
	background-color:#ff4052;
}

/*** 

====================================================================
	Performance Section
====================================================================

***/

.performance-section{
	position:relative;
	padding:100px 0px;
	background-size:cover;
	background-position:left center;
}

.performance-section .progress-column{
	position:relative;
}

.performance-section .progress-column .inner-box{
	position:relative;
	padding:50px 45px 40px;
	background-color:#ffffff;
}

.performance-section .progress-column .inner-box .title{
	position:relative;
	color:#323131;
	font-size:18px;
}

.performance-section .progress-column .inner-box .title span{
	position:relative;
	color:#bababa;
}

.performance-section .progress-column .inner-box .detail{
	position:relative;
	color:#03c7d9;
	font-size:16px;
	padding-bottom:2px;
	text-transform:uppercase;
	border-bottom:2px solid #03c7d9;
}

.performance-section .progress-column .inner-box .two-column{
	margin-top:40px;
}

.performance-section .progress-column .inner-box .percent-text{
	position:relative;
	color:#323131;
	font-size:50px;
	font-weight:700;
	margin-top:20px;
}

.performance-section .progress-column .inner-box .percent{
	position:relative;
	color:#bbbbbb;
	font-size:18px;
}

.performance-section .progress-column .two-column .text{
	position:relative;
	color:#c7c7c7;
	font-size:18px;
	margin-top:5px;
	margin-bottom:15px;
}

.performance-section .progress-column .two-column .progres-info{
	position:relative;
}

.performance-section .progress-column .two-column .progres-info li{
	position:relative;
	color:#c7c7c7;
	font-size:16px;
	padding-left:35px;
	font-weight:400;
	margin-bottom:10px;
}

.performance-section .progress-column .two-column .progres-info li:first-child:before{
	position:absolute;
	content:'';
	left:0px;
	top:6px;
	width:15px;
	height:15px;
	border-radius:2px;
	background-color:#03c7d9;
}

.performance-section .progress-column .two-column .progres-info li:nth-child(2):before{
	position:absolute;
	content:'';
	left:0px;
	top:6px;
	width:15px;
	height:15px;
	border-radius:2px;
	background-color:#bf5aff;
}

.performance-section .progress-column .two-column .progres-info li span{
	color:#000000;
	float:right;
}

.performance-section .content-column{
	position:relative;
}

.performance-section .content-column .inner-box{
	position:relative;
	padding-left:40px;
	margin-top:35px;
}

.performance-section .content-column .inner-box h2{
	position:relative;
	color:#00000a;
	font-size:38px;
	font-weight:500;
	line-height:1.3em;
	margin-bottom:30px;
}

.performance-section .content-column .inner-box .text{
	position:relative;
	font-size:15px;
	line-height:1.9em;
	margin-bottom:35px;
	color:rgba(0,0,10,0.65);
}

/*** 

====================================================================
	Default Section
====================================================================

***/

.default-section{
	position:relative;
	padding:100px 0px 80px;
}

.about-column.welcome .inner{
	padding-left:40px;
}

.default-section .about-column{
	margin-bottom:30px;
}

.default-section .column .inner .sec-title{
	margin-bottom:50px;
}

.default-section .column .inner .text{
	position:relative;
	margin-bottom:30px;
}

.default-section .column .inner .text p{
	position:relative;
	font-size:16px;
	margin-bottom:25px;
	line-height:1.8em;
	color:rgba(0,0,10,0.66);
}

.default-section .column .inner .text p:last-child{
	margin-bottom:0px;
}

.default-section .column .inner .author-info{
	position:relative;
	padding-left:110px;
	padding-top:6px;
	margin-bottom:50px;
	min-height:76px;
}

.default-section .column .inner .author-info .signature{
	margin-bottom:20px;
}

.default-section .column .inner .author-info .img-thumb{
	position:absolute;
	left:0px;
	top:0px;
	width:76px;
	height:76px;
	border-radius:50%;
	overflow:hidden;
}

.default-section .column .inner .author-info h4{
	position:relative;
	color:#323131;
	font-size:20px;
	font-weight:500;
}

.default-section .column .inner .author-info .designation{
	position:relative;
	color:#a1a1a1;
	font-size:16px;
}

/*Accordion Box*/

.accordion-box{
	position:relative;
}

.accordion-box .block{
	position:relative;
}

.accordion-box .block .acc-btn{
	position:relative;
	font-size:20px;
	cursor:pointer;
	line-height:30px;
	color:#00000a;
	font-weight:500;
	border-top:2px solid #f4f4f4;
	padding:18px 0px 20px 50px;
	transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
}

.accordion-box .block:last-child{
	border-bottom:2px solid #f1f1f1;
}

.accordion-box .block .icon-outer{
	position:absolute;
	left:0px;
	top:50%;
	width:30px;
	height:30px;
	text-align:center;
	border-radius:50%;
	font-size:16px;
	color:#04c7d9;
	margin-top:-15px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.accordion-box .block .icon-outer .icon{
	position:absolute;
	left:0px;
	top:50%;
	margin-top:-16px;
	width:100%;
	height:32px;
	line-height:32px;
	text-align:center;
	font-size:16px;
}

.accordion-box .block .icon-outer .icon-plus{
	opacity:1;
}

.accordion-box .block .icon-outer .icon-minus{
	opacity:0;
}

.accordion-box .block .acc-btn.active .icon-outer .icon-minus{
	opacity:1;
}

.accordion-box .block .acc-btn.active .icon-outer .icon-plus{
	opacity:0;
}

.accordion-box .block .acc-btn.active .icon-outer{
	color:#04c7d9;
}

.accordion-box .block .acc-content{
	position:relative;
	display:none;
}

.accordion-box .block .acc-content .content-text{
	padding-bottom:6px;
}

.accordion-box .block .acc-content.current{
	display:block;	
}

.accordion-box .block .content{
	position:relative;
	font-size:14px;
	padding:0px 0px 25px 50px;
}

.accordion-box .block .content p{
	margin-bottom:20px;
	line-height:1.8em;
	color:#777777;
	font-size:16px;	
}

.accordion-box .block .content p:last-child{
	margin-bottom:0px;	
}

.accordion-box.style-two .block .acc-btn,
.accordion-box.style-two .block:last-child{
	border:0px;
}

.accordion-box.style-two .block .acc-btn.active .icon-outer,
.accordion-box.style-two .block .icon-outer{
	color:#000000;
}

/*** 

====================================================================
	Counter Section
====================================================================

***/

.counter-section{
	position:relative;
	padding:90px 0px 60px;
	background-size:cover;
	background-attachment:fixed;
	background-repeat:no-repeat;
	background-position:center center;
}

.counter-section:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	opacity:0.9;
	background:-webkit-linear-gradient(left, #2359c3, #09a0c8, #03b1ca);
}

.counter-section.style-two{
	background-color:#ff4052;
	padding:180px 0px 150px;
}

.counter-section.style-two:before{
	display:none;
}

/*** 

====================================================================
	Fact Counter style
====================================================================

***/

.fact-counter{
	position:relative;
}

.fact-counter .column{
	position:relative;
	z-index:5;
	margin-bottom:30px;
}

.fact-counter .column .inner{
	position:relative;
	text-align:center;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.fact-counter .count-outer{
	position:relative;
}

.fact-counter .count-outer .plus-icon{
	position:relative;
	font-size:60px;
	color:#ffffff;
	line-height:1em;
	font-weight:500;
	display:inline-block;
}

.fact-counter .count-outer .count-text{
	position:relative;
	font-size:60px;
	color:#ffffff;
	line-height:1em;
	font-weight:700;
}

.fact-counter .column .counter-title{
	position:relative;
	font-weight:400;
	color:#ffffff;
	font-size:18px;
	margin-top:10px;
}

/*** 

===================================================================
	Services Section Two
====================================================================

***/

.services-section-two{
	position:relative;
	padding:110px 0px 80px;
}

.services-section-two .outer-blocks{
	position:relative;
	border:1px solid #f3f3f3;
}

.services-block-two{
	position:relative;
	margin-bottom:30px;
	padding-top:20px;
}

.services-block-two .inner-box{
	position:relative;
	padding:30px 20px;
	text-align:center;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.services-block-two .inner-box .icon-box{
	position:relative;
	width:100px;
	height:100px;
	margin:0 auto;
	color:#ffffff;
	text-align:center;
	border-radius:50%;
	margin-bottom:20px;
	font-size:48px;
	line-height:96px;
	background-image: -ms-linear-gradient(top, #12CAD0 0%, #1174de 100%);
	background-image: -moz-linear-gradient(top, #12CAD0 0%, #1174de 100%);
	background-image: -o-linear-gradient(top, #12CAD0 0%, #1174de 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #12CAD0), color-stop(100, #1174de));
	background-image: -webkit-linear-gradient(top, #12CAD0 0%, #1174de 100%);
	background-image: linear-gradient(to bottom, #12CAD0 0%, #1174de 100%);
}

.services-block-two .inner-box h3{
	position:relative;
	font-size:22px;
	font-weight:500;
	margin-bottom:15px;
}

.services-block-two .inner-box h3 a{
	position:relative;
	color:#00000a;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.services-block-two .inner-box h3 a:hover{
	color:#ff4052;
}

.services-block-two .inner-box .text{
	position:relative;
	font-size:15px;
	line-height:1.8em;
	margin-bottom:25px;
	color:rgba(0,0,10,0.65);
}

.services-block-two .inner-box .theme-btn{
	padding:9px 35px 8px;
}

.services-block-two .inner-box .arrow-box{
    position: absolute;
    left:50%;
    bottom:32px;
    width: 40px;
    height: 40px;
    color: #ffffff;
    line-height: 36px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    font-size: 30px;
    padding-left: 4px;
    opacity: 0;
	margin-left:-20px;
    background-color: #03c7d9;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.services-block-two .inner-box:hover{
	-webkit-box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
	-moz-box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
	box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
}

.services-block-two .inner-box:hover .theme-btn{
	opacity: 0;
	visibility:hidden;
}

.services-block-two .inner-box:hover .arrow-box{
	opacity:1;
}

.services-section-two .owl-nav{
	display:none;
}

.services-section-two .owl-dots{
	position:relative;
	text-align:center;
	margin-top:30px;
}

.services-section-two .owl-dots .owl-dot{
	position:relative;
	display:inline-block;
	margin:0px 4px;	
}

.services-section-two .owl-dots .owl-dot span{
	position:relative;
	display:block;
	width:10px;
	height:10px;
	margin:0px;
	background:#f1f1f1;
	border-radius:50%;
	transition:all 500ms ease;
}

.services-section-two .owl-dots .owl-dot:hover span,
.services-section-two .owl-dots .owl-dot.active span{
	background:#03c7d9;
}

/*** 

====================================================================
	Project Section
====================================================================

***/

.project-section{
	position:relative;
	padding-top:90px;
}

/*Default Gallery Item*/

.default-gallery-item{
	position:relative;
}

.default-gallery-item .inner-box{
	position:relative;
}

.default-gallery-item .inner-box .image-box{
	position:relative;
}

.default-gallery-item .inner-box .image-box img{
	position:relative;
	width:100%;
	display:block;
}

.default-gallery-item .inner-box .overlay-box{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    color: #ffffff;
    opacity: 1;
    text-align: center;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    -moz-transform: scaleY(0);
    transform: scaleY(0);
}

.default-gallery-item .inner-box .overlay-box .overlay-inner{
	position:relative;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: table;
    vertical-align: middle;
    padding: 10px 20px;
    background: rgba(3,199,217,0.90);
}

.default-gallery-item .inner-box .overlay-box .overlay-inner .content{
    position: relative;
    display: table-cell;
    vertical-align: middle;
}

.default-gallery-item .inner-box .overlay-box .overlay-inner .content .category{
	position:relative;
	font-size:16px;
	text-transform:capitalize;
	color:#ffffff;
}

.default-gallery-item .inner-box .overlay-box .overlay-inner .content h4{
	position:relative;
	font-size:24px;
	font-weight:500;
	margin-bottom:12px;
	text-transform:capitalize;
}

.default-gallery-item .inner-box .overlay-box .overlay-inner .content h4 a{
	color:#ffffff;
}

.default-gallery-item .inner-box .overlay-box .overlay-inner .content .option-btn{
	position:relative;
	color:#ffffff;
	font-size:16px;
	width:45px;
	height:45px;
	line-height:43px;
	text-align:center;
	border-radius:50%;
	display:inline-block;
	border:2px solid #ffffff;
}

.default-gallery-item .inner-box:hover .overlay-box{
	top: 0px;
    opacity: 1;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -moz-transform: scaleY(1);
    transform: scaleY(1);
}

.project-section .owl-dots,
.project-section .owl-nav{
	display:none;
}

/*** 

====================================================================
	Call To Action
====================================================================

***/

.call-to-action{
	position:relative;
	padding:35px 0px 15px;
	background-color:#1f1f1f;
}

.call-to-action .column{
	position:relative;
	margin-bottom:20px;
}

.call-to-action .column .text{
	position:relative;
	color:#ffffff;
	font-size:22px;
	font-weight:300;
	line-height:1.4em;
	margin-top:10px;
}

/*** 

====================================================================
	Price Section
====================================================================

***/

.price-section{
	position:relative;
	padding:110px 0px 80px;
}

.price-section .price-column{
	position:relative;
}

.price-section .price-column .price-block{
	position:relative;
	padding:0px;
	border-left:1px solid #f0f0f0;
}

.price-section .price-column .price-block:last-child{
	border-right:1px solid #f0f0f0;
}

.price-block .inner-box{
	position:relative;
	padding:35px 30px;
	text-align:center;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.price-block .inner-box h2{
	position:relative;
	color:#00000a;
	font-size:20px;
	font-weight:600;
	margin-bottom:15px;
	text-transform:uppercase;
}

.price-block .inner-box:hover{
	-webkit-box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
	-moz-box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
	box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
}

.price-block .inner-box .icon-box{
	position:relative;
	color:#03c7d9;
	font-size:56px;
	line-height:1em;
	margin-bottom:20px;
	display:inline-block;
}

.price-block .inner-box .text{
	position:relative;
	font-size:15px;
	line-height:1.8em;
	margin-bottom:25px;
	color:rgba(0,0,10,0.80);
}

.price-block .inner-box .price{
	position:relative;
	color:#03c7d9;
	font-size:48px;
	font-weight:700;
	line-height:1em;
	margin-bottom:25px;
}

.price-block .inner-box .price sup{
	position:relative;
	top:-18px;
	font-size:18px;
	font-weight:400;
	margin-right:5px;
	color:rgba(51,51,51,0.50);
}

.price-block .inner-box .theme-btn{
	padding:11px 36px 11px;
}

.price-block.style-two .inner-box{
	padding-top:80px;
	padding-bottom:80px;
}

.price-block.style-two .inner-box .icon-box{
	color:#e5e5e6;
}

.price-block.style-two .inner-box .price{
	color:#00000a;
	margin-top:30px;
	margin-bottom:35px;
}

.price-block.style-two .inner-box .text{
	max-width:220px;
	margin:0 auto;
}

.price-block.style-two .inner-box .theme-btn:hover{
	background-color:#ff4052;
	border-color:#ff4052;
	opacity:1;
}

/*** 

====================================================================
	Testimonial Section
====================================================================

***/

.testimonial-section{
	position:relative;
	padding:120px 0px 90px;
	background-size:cover;
	background-attachment:fixed;
	background-repeat:no-repeat;
	background-position:center center;
}

.testimonial-section:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-color:rgba(19,145,252,0.10);
}

.testimonial-section .testimonial-column{
	position:relative;
}

.testimonial-section .testimonial-column .inner-column{
	position:relative;
	padding:60px 60px;
	margin-right:80px;
	background-color:#ffffff;
}

.testimonial-section .testimonial-column .inner-column .quote-icon{
	position:absolute;
	left:-32px;
	top:65px;
	width:65px;
	height:65px;
	color:#ffffff;
	text-align:center;
	line-height:65px;
	border-radius:50%;
	font-size:28px;
	background-image: -ms-linear-gradient(top, #12CAD0 0%, #1174de 100%);
	background-image: -moz-linear-gradient(top, #12CAD0 0%, #1174de 100%);
	background-image: -o-linear-gradient(top, #12CAD0 0%, #1174de 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #12CAD0), color-stop(100, #1174de));
	background-image: -webkit-linear-gradient(top, #12CAD0 0%, #1174de 100%);
	background-image: linear-gradient(to bottom, #12CAD0 0%, #1174de 100%);
}

.testimonial-block{
	position:relative;
}

.testimonial-block .inner-box{
	position:relative;
}

.testimonial-block .inner-box .bold-text{
	position:relative;
	color:#00000a;
	font-size:18px;
	line-height:1.8em;
	margin-bottom:30px;
}

.testimonial-block .inner-box .text{
	position:relative;
	color:rgba(0,0,10,0.65);
	font-size:16px;
	line-height:1.8em;
	margin-bottom:30px;
}

.testimonial-block .inner-box .author-box{
	position:relative;
}

.testimonial-block .inner-box .author-box .author-inner{
	position:relative;
	padding-left:85px;
}

.testimonial-block .inner-box .author-box .author-inner .image{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:60px;
	overflow:hidden;
	border-radius:4px;
}

.testimonial-block .inner-box .author-box .author-inner h3{
	position:relative;
	color:#00000a;
	font-size:18px;
	font-weight:600;
	text-transform:capitalize;
}

.testimonial-block .inner-box .author-box .author-inner .designation{
	position:relative;
	color:#a1a1a1;
	font-size:15px;
}

.testimonial-section .owl-nav{
	display:none;
}

.testimonial-section .owl-dots{
	position:relative;
	text-align:right;
	margin-top:-25px;
}

.testimonial-section .owl-dots .owl-dot{
	position:relative;
	display:inline-block;
	margin:0px 4px;	
}

.testimonial-section .owl-dots .owl-dot span{
	position:relative;
	display:block;
	width:10px;
	height:10px;
	margin:0px;
	background:#f1f1f1;
	border-radius:50%;
	transition:all 500ms ease;
}

.testimonial-section .owl-dots .owl-dot:hover span,
.testimonial-section .owl-dots .owl-dot.active span{
	background:#03c7d9;
}

/*** 

====================================================================
	Location Section
====================================================================

***/

.location-section{
	position:relative;
	padding:110px 0px 90px;
}

.map-box{
	position:relative;
	text-align:center;
	margin-bottom:60px;
}

.map-box .map-icon{
	position:absolute;
	left:33%;
	top:65%;
}

.map-box .map-icon .icon{
	cursor:pointer;
}

.map-box .map-icon:hover .map-detail-box{
	opacity:1;
	visibility:visible;
}

.map-box .map-icon .map-detail-box{
	position:absolute;
	left:-180px;
	width:365px;
	opacity:0;
	visibility:hidden;
	padding:30px 20px 25px;
	text-align:center;
	bottom:130% !important;
	background-color:#ffffff;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
	-webkit-box-shadow:2px 2px 15px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:2px 2px 15px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 15px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:2px 2px 15px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 15px 1px rgba(0,0,0,0.05);
}

.map-box .map-icon .map-detail-box h3{
	position:relative;
	color:#00000a;
	font-size:20px;
	font-weight:600;
	margin-bottom:15px;
	line-height:1em;
}

.map-box .map-icon .map-detail-box .text{
	position:relative;
	font-size:15px;
	line-height:1.8em;
	color:rgba(33,33,33,0.65);
}

.location-section .map-info-section{
	position:relative;
}

.location-section .map-info-section .column:nth-child(2){
	text-align:center;
}

.location-section .map-info-section .column:nth-child(3){
	text-align:right;
}

/*Map Info*/

.location-section .map-info{
	position:relative;
	min-height:70px;
	margin-bottom:30px;
}

.location-section .map-info .inner{
	position:relative;
	padding-left:110px;
	display:inline-block;
}

.location-section .map-info .inner .icon{
	position:absolute;
	left:0px;
	top:5px;
	width:85px;
	height:85px;
	color:#bcbcbc;
	font-size:40px;
	line-height:78px;
	text-align:center;
	border-radius:50%;
	margin-bottom:20px !important;
	border:2px solid #ececec;
}

.location-section .map-info .inner h3{
	position:relative;
	color:#00000a;
	font-size:22px;
	font-weight:600;
	text-align:left;
	margin-bottom:6px;
}

.location-section .map-info .inner .text{
	font-size:15px;
	line-height:1.7em;
	text-align:left;
	color:rgba(51,51,51,0.65);
}

/*** 

====================================================================
	Main Footer
====================================================================

 ***/

.main-footer{
	position:relative;
	padding:80px 0px 80px;
	background-image: -ms-linear-gradient(top, #F8FCFF 0%, #ffffff 100%);
	background-image: -moz-linear-gradient(top, #F8FCFF 0%, #ffffff 100%);
	background-image: -o-linear-gradient(top, #F8FCFF 0%, #ffffff 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #F8FCFF), color-stop(100, #ffffff));
	background-image: -webkit-linear-gradient(top, #F8FCFF 0%, #ffffff 100%);
	background-image: linear-gradient(to bottom, #F8FCFF 0%, #ffffff 100%);
}

.main-footer h2{
	position:relative;
	color:#00000a;
	font-size:36px;
	text-align:center;
	font-weight:600;
	margin-bottom:80px;
}

/*Subscribe widget*/

.subscribe-form{
	position:relative;
	max-width:970px;
	margin:0 auto;
	margin-bottom:55px;
}

.subscribe-form .form-group{
	position:relative;
	display:block;
	margin-bottom:25px;
}

.subscribe-form .form-group input[type="text"],
.subscribe-form .form-group input[type="email"]{
	position:relative;
	display:block;
	width:100%;
	height:60px;
	color:#000000;
	font-size:16px;
	line-height:38px;
	background:#ffffff;
	padding:10px 20px 10px;
	border:1px solid #f0f5f8;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.subscribe-form .form-group input[type="text"]:focus,
.subscribe-form .form-group input[type="email"]:focus{
	border-color:#03c7d9;	
}

.subscribe-form .form-group input[type="submit"],
.subscribe-form button{
	padding:18px 45px;
	width:100%;
	font-size:15px;
	border-radius:4px;
	text-transform:uppercase;
}

.main-footer .footer-bottom{
	position:relative;
	max-width:970px;
	margin:0 auto;
}

.main-footer .footer-bottom .logo{
	position:relative;
	margin-bottom:15px;
}

.main-footer .footer-bottom .copyright{
	position:relative;
	color:#909090;
	font-size:16px;
}

.main-footer .footer-bottom .copyright a{
	color:#000000;
}

.main-footer .footer-bottom .footer-nav{
	position:relative;
	text-align:right;
	margin-top:8px;
	margin-bottom:18px;
}

.main-footer .footer-bottom .footer-nav li{
	position:relative;
	margin-left:15px;
	display:inline-block;
}

.main-footer .footer-bottom .footer-nav li a{
	position:relative;
	font-size:16px;
	color:rgba(4,0,10,0.70);
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.main-footer .footer-bottom .footer-nav li a:hover{
	color:#03c7d9;
}

/*Footer Style Two*/

.footer-style-two{
	position:relative;
}

.footer-style-two .widgets-section{
	position:relative;
	padding-top: 34px;
}

.footer-style-two .footer-column{
	margin-bottom:30px;	
}

.footer-style-two .logo-widget .logo-box{
	margin-bottom:10px;
}

.footer-style-two .logo-widget .text{
	position:relative;
	font-size:16px;
	line-height:1.6em;
	color:rgba(0,0,0,0.40);
}

.footer-style-two .footer-column h2{
	font-size:20px;
	font-weight:600;
	color:#222328;
	margin-bottom:15px;
	text-transform:capitalize;
}

.footer-style-two .link-widget{
	position:relative;
}

.footer-style-two .link-widget .widget-content ul li{
	position:relative;
	margin-bottom:5px;
}

.footer-style-two .link-widget .widget-content ul li a{
	position:relative;
	font-size:16px;
	color:rgba(0,0,0,0.40);
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.footer-style-two .link-widget .widget-content ul li a:hover{
	color:rgba(0,0,0,1);
}

.footer-style-two .subscribe-widget .social-icon-two{
	position:relative;
	margin-top:10px;
	margin-bottom:25px;
}

.footer-style-two .subscribe-widget .social-icon-two li{
	margin-left:0px;
	margin-right:15px;
}

.footer-style-two .subscribe-widget .text{
	position:relative;
	font-size:16px;
	line-height:1.6em;
	margin-bottom:20px;
	color:rgba(0,0,0,0.40);
}




.footer-style-two .subscribe-widget .email-form .form-group{
	position:relative;
	display:block;
	margin-bottom:10px;
}

.footer-style-two .email-form .form-group input[type="email"]{
	position:relative;
	display:block;
	width:100%;
	height:42px;
	color:#222222;
	font-size:14px;
	border-radius:50px;
	line-height:22px;
	padding:10px 20px;
	background:#eff0f4;
	max-width:205px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
}

.footer-style-two .email-form .form-group input[type="email"]:focus{
	background:#f1f1f1;	
}

.footer-style-two .email-form .form-group input[type="submit"],
.footer-style-two .email-form button{
	position:absolute;
	right:0px;
	top:0px;
	color:#ffffff;
	font-size:24px;
	width:42px;
	height:42px;
	display:block;
	line-height:30px;
	text-align:center;	
	padding-left:2px;
	background-color:#1ecde2;
	border-radius:50px;
}

.footer-style-two .footer-bottom{
	position:relative;
	padding:50px 0px 50px;
	border-top:1px solid #e6e6e9;
}

.footer-style-two .footer-bottom .copyright{
	position:relative;
	color:#222328;
	font-size:15px;
}

.footer-style-two .footer-bottom .counter-column{
	position:relative;
	text-align:right;
}

.footer-style-two .footer-bottom .counter-column .text{
	position:relative;
	font-weight:800;
	color:#222328;
	font-size:28px;
	margin-bottom:15px;
}

.footer-style-two .footer-bottom .counter-column .text .count-text{
	position:relative;
	font-weight:800;
	color:#04cae7;
	font-size:28px;
	margin-right:6px;
}

.footer-style-two.alternate .auto-container{
	position:relative;
}

.footer-style-two.alternate .big-letter{
	position:absolute;
	right:0px;
	top:-220px;
	color:#f6f8f9;
	font-size:300px;
	line-height:1em;
	font-weight:700;
	text-transform:uppercase;
}

.footer-style-two.alternate{
	background:url(/images/custom/footer_bg.jpg) center no-repeat;
	background-size: cover;
}

.footer-style-two.alternate-two{
	padding-top:50px;
}

.footer-style-two.alternate .footer-column h2{
	font-size:16px;
	margin-bottom:30px;
	text-transform:uppercase;
}

.footer-style-two.alternate .logo-widget .email{
	color:#222222;
	font-weight:500;
	font-size:16px;
}

.footer-style-two.alternate .logo-widget .number{
	color:#ff5151;
	font-size:16px;
	font-weight:500;
	margin-top:10px;
}

.footer-style-two.alternate .email-form .form-group input[type="email"]{
	border-radius:0px;
	background:none;
	height:55px;
	max-width:100%;
	margin-bottom:20px;
	border:2px solid #f2f2f2;
}

.footer-style-two.alternate .footer-bottom{
	padding-top:0px;
	border:0px;
	padding-bottom:150px;
}

.footer-style-two.alternate .footer-bottom .counter-column .text{
	font-size:24px;
	font-weight:600;
}

.footer-style-two.alternate .footer-bottom .counter-column .text .count-text{
	color:#ff5151;
	font-size:24px;
	font-weight:600;
}

.footer-style-two.alternate .footer-bottom .copyright{
	color:#9c9c9f;
	font-size:16px;
}

/*Services Block Three*/

.services-section-two .services-block-three{
	position:relative;
	padding:0px;
	border-right:1px solid #f3f3f3;
}

.services-block-three:last-child{
	border-right:0px;
}

.services-block-three .inner-box{
	position:relative;
	text-align:center;
	padding:35px 15px 0px;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.services-block-three .inner-box:hover{
	background-color:#04cae7;
}

.services-block-three .inner-box:hover h3 a,
.services-block-three .inner-box:hover .icon-box,
.services-block-three .inner-box:hover .text{
	color:#ffffff;
}

.services-block-three .inner-box:hover .arrow-box{
	color:#04cae7;
}

.services-block-three .inner-box .icon-box{
	position:relative;
	color:#04cae7;
	font-size:50px;
	line-height:1em;
	margin-bottom:15px;
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.services-block-three .inner-box h3{
	position:relative;
	font-weight:600;
	font-size:20px;
	margin-bottom:15px;
	text-transform:capitalize;
}

.services-block-three .inner-box h3 a{
	color:#1f1f1f;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
}

.services-block-three .inner-box .text{
	position:relative;
	font-size:16px;
	line-height:1.8em;
	margin-bottom:25px;
	color:rgba(0,0,10,0.65);
	transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.services-block-three .inner-box .arrow-box{
	position:relative;
    width: 40px;
    height: 40px;
    color: #f3f3f3;
    line-height: 34px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    font-size: 30px;
    padding-left: 4px;
	bottom:-20px;
	border:2px solid #f3f3f3;
    background:#ffffff;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

/*** 

====================================================================
	About Section
====================================================================

 ***/

.about-section{
	position:relative;
	padding:50px 0px 50px;
}

.about-section .count-column{
	position:relative;
	margin-bottom:40px;
}

.about-section .count-column .inner-column{
	position:relative;
	padding:50px;
	border-radius:50%;
	border:1px solid #f8f8f8;
}

.about-section .count-column .inner-column .content{
	position:relative;
	max-width:450px;
	margin:0 auto;
	overflow:hidden;
	border-radius:50%;
}

.about-section .count-column .inner-column .content .column-inner{
	position:relative;
	padding:60px 0px;
	text-align:center;
	height:220px;
	background-color:#04cae7;
}

.about-section .count-column .inner-column .content .image-column{
	position:relative;
	height:220px;
	padding:0px;
	outline:3px solid #ffffff;
	background-size:cover;
}

.about-section .count-column .inner-column .content .column{
	position:relative;
	padding:0px;
	outline:3px solid #ffffff;
}

.about-section .count-column .inner-column .content .column:last-child .column-inner{
	background-color:#ffc343;
	padding:40px 0px;
	text-align:left;
	padding-left:25px;
}

.about-section .about-column{
	position:relative;
}

.about-section .about-column .column-inner{
	position:relative;
}

.about-section .count-column .inner-column .content .column-inner .percent-text{
	position:relative;
	color:#ffffff;
	font-size:48px;
	font-weight:700;
	line-height:1em;
	margin-top:15px;
	margin-bottom:4px;
}

.about-section .count-column .inner-column .content .column-inner .text{
	position:relative;
	color:#ffffff;
	font-size:18px;
}

.about-section .about-column .column-inner h2{
	position:relative;
	color:#00000a;
	font-size:45px;
	font-weight:800;
	line-height:1.2em;
	margin-bottom:25px;
	text-transform:capitalize;
}

.about-section .about-column .column-inner .text{
	position:relative;
	margin-bottom:40px;
}

.about-section .about-column .column-inner .text p{
	position:relative;
	font-size:16px;
	line-height:1.8em;
	margin-bottom:20px;
	color:rgba(0,0,0,0.40);
}

.about-section .about-column .column-inner .text p:last-child{
	margin-bottom:0px;
}

.about-section .about-column .column-inner .author-info{
	position:relative;
	padding-left:110px;
	padding-top:6px;
	margin-bottom:30px;
	min-height:76px;
}

.about-section .about-column .column-inner .author-info .signature{
	margin-bottom:20px;
}

.about-section .about-column .column-inner .author-info .img-thumb{
	position:absolute;
	left:0px;
	top:0px;
	width:76px;
	height:76px;
	border-radius:50%;
	overflow:hidden;
}

.about-section .about-column .column-inner .author-info h4{
	position:relative;
	color:#00000a;
	font-size:18px;
	font-weight:500;
}

.about-section .about-column .column-inner .author-info .designation{
	position:relative;
	color:#959595;
	font-size:15px;
}

/*** 

====================================================================
	Growth Section
====================================================================

 ***/
 
.growth-section{
	position:relative;
	padding:100px 0px 60px;
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center center;
}

.growth-section:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	z-index:10;
	background: -webkit-linear-gradient(left, #f8fcff, rgba(255,255,255,0.50));
	background: -o-linear-gradient(left, #f8fcff, rgba(255,255,255,0.50));
	background: -ms-linear-gradient(left, #f8fcff, rgba(255,255,255,0.50));
	background: -moz-linear-gradient(left, #f8fcff, rgba(255,255,255,0.50));
}

.growth-section .content-column{
	position:relative;
	margin-bottom:40px;
}

.growth-section .content-column .column-inner{
	position:relative;
	padding-right:60px;
	z-index:10;
}

.growth-section .content-column .column-inner h2{
	position:relative;
	font-size:45px;
	font-weight:800;
	color:#262626;
	line-height:1.2em;
	margin-bottom:25px;
}

.growth-section .content-column .column-inner h2 span{
	color:#04cae7;
}

.growth-section .content-column .column-inner .text{
	position:relative;
}

.growth-section .content-column .column-inner .text p{
	position:relative;
	font-size:16px;
	margin-bottom:20px;
	color:rgba(1,1,1,0.45);
}

.growth-section .content-column .column-inner .text p:last-child{
	margin-bottom:0px;
}

.growth-section .graph-column{
	position:relative;
}

.growth-section .graph-column .inner-column{
	position:relative;
	padding:40px 40px;
	z-index:10;
	background-color:#ffffff;
	-webkit-box-shadow:2px 2px 15px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:2px 2px 15px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 15px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:2px 2px 15px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 15px 1px rgba(0,0,0,0.05);
}

.growth-section .graph-column .inner-column h3{
	position:relative;
	color:#262626;
	font-size:18px;
	font-weight:700;
	margin-bottom:30px;
}

.growth-section .graph-column .inner-column .graph-img img{
	width:100%;
	display:block;
}

.growth-section .graph-column .inner-column h3 span{
	color:#cdcdcd;
	font-weight:400;
}

.growth-section .graph-column .inner-column .detail-link{
	position:relative;
	color:#34c1e4;
	font-size:16px;
	font-weight:700;
	padding-bottom:4px;
	border-bottom:2px solid #34c1e4;
}

/*** 

====================================================================
	Pricing Section
====================================================================

 ***/

.pricing-section{
	position:relative;
	padding:110px 0px 90px;
}

.pricing-block:first-child:before{
	position:absolute;
	content:'';
	left:100%;
	top:0px;
	width:1px;
	height:100%;
	background-color:#e8eaf1;
}

.pricing-block:nth-child(2) .inner-box .icon-box{
	background-color:#ffc343;
}

.pricing-block{
	position:relative;
	margin-bottom:40px;
}

.pricing-block .inner-box{
	position:relative;
	padding:0px 74px;
}

.pricing-block .inner-box .icon-box{
	position:relative;
	width:90px;
	height:90px;
	color:#ffffff;
	font-size:42px;
	line-height:88px;
	text-align:center;
	border-radius:50%;
	margin-bottom:28px;
	background-color:#03c7d9;
}

.pricing-block .inner-box h3{
	position:relative;
	color:#262626;
	font-size:18px;
	font-weight:700;
	margin-bottom:12px;
	text-transform:uppercase;
}

.pricing-block .inner-box .price{
	position:relative;
	font-size:52px;
	font-weight:800;
	color:#262626;
	line-height:1em;
	margin-bottom:22px;
}

.pricing-block .inner-box .price sup{
	position:relative;
	top:-20px;
	color:#a8acb9;
	font-size:16px;
	font-weight:300;
	margin-right:10px;
}

.pricing-block .inner-box .price sub{
	position:relative;
	color:#a8acb9;
	font-size:14px;
	font-weight:300;
}

.pricing-block .inner-box .text{
	position:relative;
	font-size:16px;
	line-height:1.6em;
	margin-bottom:40px;
	color:rgba(0,0,0,0.40);
}

.pricing-block .inner-box .theme-btn{
	font-size:13px;
	margin-top:35px;
	padding:8px 28px 8px;
}

/*** 

====================================================================
	Fullwidth Section
====================================================================

***/

.fluid-section{
	position:relative;
	background-color:#222328;
}

.fluid-section .background-image{
	position:absolute;
	left:0px;
	top:0px;
	width:50%;
	height:100%;
	background-position:center center;
	background-repeat:no-repeat;
	background-size:cover;
}

.fluid-section .background-image:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	background-color:rgba(0,0,0,0.30);
}

.fluid-section .testimonial-column{
	position:relative;
}

.fluid-section .testimonial-column .inner{
	position:relative;
	padding:225px 0px 180px;
}

.fluid-section .testimonial-column .testimonial-style-one{
	position:relative;
	
}

.fluid-section .testimonial-column .inner .testimonial-inner .owl-dots,
.fluid-section .testimonial-column .inner .testimonial-inner .owl-nav{
	display:none;
}

.fluid-section .testimonial-column .inner .testimonial-inner{
	position:relative;
	padding:45px 30px 50px 48px;
	max-width:450px;
	width:100%;
	margin-right:20px;
	margin-left:-40px;
	background-color:#04cae7;
}

.fluid-section .testimonial-column .inner .testimonial-inner .quote-icon{
	position:absolute;
	left:0px;
	top:50%;
	width:45px;
	height:45px;
	color:#04cae7;
	font-size:18px;
	line-height:46px;
	text-align:center;
	border-radius:50%;
	margin-top:-22px;
	z-index:10;
	overflow:hidden;
	margin-left:-22px;
}

.fluid-section .testimonial-column .inner .testimonial-inner .quote-icon .icon{
	position:relative;
	display:block;
	background-color:#ffffff;
}

.testimonial-box{
	position:relative;
}

.testimonial-box .inner-box{
	position:relative;
}

.testimonial-box .inner-box .text{
	position:relative;
	font-size:18px;
	font-weight:400;
	line-height:1.6em;
	color:#ffffff;
}

.testimonial-box .inner-box h4{
	position:relative;
	color:#ffffff;
	font-size:18px;
	font-weight:500;
	margin-top:20px;
	padding-left:40px;
	display:inline-block;
	text-transform:uppercase;
}

.testimonial-box .inner-box h4:before{
	position:absolute;
	content:'';
	left:0px;
	top:15px;
	width:26px;
	height:2px;
	background-color:#ffffff;
}

.fluid-section .content-column{
	position:relative;
	margin-bottom:30px;
}

.fluid-section .content-column .inner{
	position:relative;
	padding:75px 20px 0px 90px;
}

.fluid-section .content-column .inner h2{
	position:relative;
	color:#ffffff;
	font-size:45px;
	font-weight:800;
	margin-bottom:40px;
	text-transform:capitalize;
}

.featured-info-block{
	position:relative;
	margin-bottom:50px;
}

.featured-info-block .inner-box{
	position:relative;
	padding-left:105px;
	min-height:86px;
}

.featured-info-block .inner-box .icon-box{
	position:absolute;
	left:0px;
	top:0px;
	width:80px;
	font-size:34px;
	line-height:80px;
	height:80px;
	border-radius:50%;
	text-align:center;
	margin-bottom:20px !important;
	background-color:#292b32;
}

.featured-info-block .inner-box .icon-box .icon{
	position:relative;
	color:#03c7d9;
}

.featured-info-block .inner-box .icon-box:after{
	position:absolute;
	content:'';
	left:50%;
	top:100%;
	width:2px;
	height:100px;
	margin-left:-1px;
	background-color:#292b32;
}

.featured-info-block:last-child .inner-box .icon-box:after{
	display:none;
}

.featured-info-block .inner-box h3{
	position:relative;
	font-size:20px;
	font-weight:600;
	line-height:1em;
	margin-bottom:15px;
}


.featured-info-block .inner-box h3 a{
	position:relative;
	color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.featured-info-block .inner-box h3 a:hover{
	color:#03c7d9;
}

.featured-info-block .inner-box .text{
	position:relative;
	color:#7d808c;
	font-size:16px;
}

/*** 

====================================================================
	Team Section
====================================================================

***/

.team-section{
	position:relative;
	padding:100px 0px 80px;
}

.team-section .sec-title .text{
	margin-top:10px !important;
}

.team-block{
	position:relative;
	margin-bottom:40px;
}

.team-block .inner-box{
	position:relative;
	border-radius:10px;
	padding:35px 20px;
	text-align:center;
	border:1px solid #eaebef;
}

.team-block .inner-box .image{
	position:relative;
	width:160px;
	height:160px;
	margin:0 auto;
	overflow:hidden;
	border-radius:50%;
	margin-bottom:25px;
}

.team-block .inner-box h3{
	position:relative;
	color:#222328;
	font-size:20px;
	font-weight:600;
}

.team-block .inner-box .designation{
	position:relative;
	color:#9196a7;
	font-size:13px;
	letter-spacing:1px;
	margin-bottom:18px;
	text-transform:uppercase;
}

.team-block .inner-box .text{
	position:relative;
	font-size:16px;
	line-height:1.6em;
	margin-bottom:15px;
	color:rgba(0,0,0,0.45);
}

/*** 

====================================================================
	Testimonial Section Two
====================================================================

***/

.testimonial-section-two{
	position:relative;
	padding:90px 0px;
	background-color:#222328;
}

.testimonial-section-two .sec-title h2 span{
	color:#04cae7;
}

.testimonial-section-two .owl-dots,
.testimonial-section-two .owl-nav{
	display:none;
}

/*Client Box*/

.client-box{
	position:relative;
	margin-bottom:60px;
}

.client-box .inner-box{
	position:relative;
	padding:35px 35px;
	border-radius:3px;
	border:1px solid rgba(255,255,255,0.10);
}

.client-box .inner-box .content{
	position:relative;
	padding-left:90px;
}

.client-box .inner-box .content .icon-box{
	position:absolute;
	left:0px;
	top:0px;
	width:65px;
}

.client-box .inner-box .content .icon-box img{
	width:65px;
	height:65px;
	border-radius:50%;
	overflow:hidden;
}

.client-box .inner-box .content h3{
	position:relative;
	color:#ffffff;
	font-size:20px;
	font-weight:600;
	margin-bottom:5px;
	text-transform:capitalize;
}

.client-box .inner-box .content .designation{
	position:relative;
	font-size:16px;
	font-weight:300;
	margin-bottom:5px;
	letter-spacing:1px;
	text-transform:uppercase;
	color:rgba(255,255,255,0.50);
}

.client-box .inner-box .content .text{
	position:relative;
	font-size:16px;
	margin-top:12px;
	line-height:1.8em;
	color:rgba(255,255,255,0.65);
}

.client-section .owl-dots,
.client-section .owl-nav{
	display:none;
}

/*** 

====================================================================
	Info Section
====================================================================

***/

.info-section{
	position:relative;
	padding:85px 0px 90px;
}

.info-section .column h2{
	position:relative;
	color:#00000a;
	font-size:42px;
	font-weight:800;
	line-height:1em;
	margin-bottom:35px;
}

.info-section .form-box{
	position:relative;
	padding:25px 30px;
	border:1px solid #f3f3f3;
	-webkit-box-shadow:0px 0px 15px 0px rgba(0,0,0,0.05);
	-ms-box-shadow:0px 0px 15px 0px rgba(0,0,0,0.05);
	-o-box-shadow:0px 0px 15px 0px rgba(0,0,0,0.05);
	-moz-box-shadow:0px 0px 15px 0px rgba(0,0,0,0.05);
	box-shadow:0px 0px 15px 0px rgba(0,0,0,0.05);
}





.default-form{
	margin-top:10px;
}

.default-form .form-group{
	position:relative;
	margin-bottom:20px;
}

.default-form .form-group .field-label{
	position:relative;
	display:block;
	color:#a8acb9;
	font-size:12px;
	font-weight:300;
	margin-bottom:5px;
	letter-spacing:1px;
	text-transform:uppercase;
}

.default-form .form-group input[type="text"],
.default-form .form-group input[type="password"],
.default-form .form-group input[type="tel"],
.default-form .form-group input[type="email"],
.default-form .form-group select{
	position:relative;
	display:block;
	width:100%;
	color:#222222;
	height:42px;
	line-height:22px;
	padding:10px 15px;
	border-radius:4px;
	background:#eff0f4;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.default-form .form-group input[type="text"]:focus,
.default-form .form-group input[type="password"]:focus,
.default-form .form-group input[type="tel"]:focus,
.default-form .form-group input[type="email"]:focus,
.default-form .form-group select:focus,
.default-form .form-group textarea:focus{
	background-color:#ffffff;
	-webkit-box-shadow:0px 6px 20px 2px rgba(0,0,0,0.05);
	-ms-box-shadow:0px 6px 20px 2px rgba(0,0,0,0.05);
	-o-box-shadow:0px 6px 20px 2px rgba(0,0,0,0.05);
	-moz-box-shadow:0px 6px 20px 2px rgba(0,0,0,0.05);
	box-shadow:0px 6px 20px 2px rgba(0,0,0,0.05);
}

.default-form .form-group textarea{
	position:relative;
	display:block;
	width:100%;
	color:#222222;
	height:120px;
	resize:none;
	line-height:24px;
	padding:8px 15px;
	background:#eff0f4;
	border-radius:4px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.default-form button{
	position:relative;
	padding:11px 31px;
	display:block;
	color:#ffffff;
	width:100%;
	margin-top:5px;
	border-radius:3px;
	background-color:#04cae7;
}

.info-section .info-blocks{
	position:relative;
	margin-top:60px;
}

.info-section .info-blocks{
	position:relative;
}

/*Info Block*/

.info-section .info-block{
	position:relative;
	min-height:70px;
	margin-bottom:30px;
}

.info-section .info-block .inner{
	position:relative;
	padding-left:110px;
	display:inline-block;
}

.info-section .info-block .inner .icon{
	position:absolute;
	left:0px;
	top:5px;
	width:85px;
	height:85px;
	color:#1ecde2;
	font-size:36px;
	line-height:80px;
	text-align:center;
	border-radius:50%;
	margin-bottom:20px !important;
	border:2px solid #e8e8e8;
}

.info-section .info-block .inner h3{
	position:relative;
	color:#00000a;
	font-size:20px;
	font-weight:600;
	margin-bottom:6px;
}

.info-section .info-block .inner .text{
	font-size:16px;
	line-height:1.7em;
	color:rgba(0,0,0,0.70);
}

.info-section .info-block:nth-child(2) .inner .icon{
	color:#d765f0;
}

.info-section .info-block:nth-child(3) .inner .icon{
	color:#ffcd61;
}

/*** 

====================================================================
	Sponsors Section
====================================================================

 ***/

.sponsors-section{
	position:relative;
	padding:35px 0px;
	background-color:#f8fcff;
}

.sponsors-section .image-box{
	position:relative;
	text-align:center;
}

.sponsors-section .image-box a{
	position:relative;
	display:block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.sponsors-section .image-box a img{
	width:auto;
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.sponsors-section .image-box:hover a img{
	opacity:0.8;
}

.sponsors-section .owl-nav,
.sponsors-section .owl-dots{
	display:none;
}

/*** 

====================================================================
	Subscribe Section
====================================================================

 ***/
 
.subscribe-section{
	position:relative;
	padding:50px 0px;
	background-color:#191b25;
}

.subscribe-section h2{
	position:relative;
	font-size:32px;
	font-weight:800;
	color:#ffffff;
}

.subscribe-section .btn-column{
	position:relative;
	text-align:right;
}

/*** 

====================================================================
	About Section Two
====================================================================

 ***/

.about-section-two{
	position:relative;
	z-index:10;
	padding:80px 0px 0px;
}

.about-section-two .big-letter{
	position:absolute;
	right:20px;
	top:0px;
	color:#f6f8f9;
	font-size:345px;
	font-weight:700;
	line-height:1em;
}

.about-section-two .auto-container{
	position:relative;
}

.about-section-two .section-count{
	position: absolute;
    right: -230px;
    bottom: 110%;
    width: 300px;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: left top;
}

.about-section-two .section-count .video-box{
	position: absolute;
    top: -10px;
    left: 100%;
    width: 230px;
	font-size:18px;
	color:#ffffff;
	font-weight:400;
    margin-left: 100px;
	padding-left:100px;
}

.about-section-two .section-count .video-box span{
	font-weight:600;
}

.about-section-two .section-count .video-box .icon{
	position:absolute;
	left:0px;
	width:60px;
	height:60px;
	color:#ffffff;
	font-size:24px;
	line-height:60px;
	text-align:center;
	display:inline-block;
	border-radius:50%;
	padding-left:4px;
	-ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
	background-color:rgba(255,255,255,0.40);
}

.about-section-two .section-count .video-box .icon:after{
	position:absolute;
	content:'';
	left:-12px;
	top:-12px;
	right:-12px;
	bottom:-12px;
	border-radius:50%;
	background-color:rgba(255,255,255,0.20);
}

.about-section-two .section-count .count{
	position: relative;
    font-size: 53px;
    font-weight: 300;
    line-height: 1em;
    color: rgba(0,0,10,0.17);
}

.about-section-two .section-count:after{
	position: absolute;
    content: '';
    left: 80px;
    top: 25px;
    height: 250px;
    width: 2px;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: left top;
    background-color: #ededed;
}

.about-section-two .column .text{
	position:relative;
	margin-top:20px;
}

.about-section-two .column .text p{
	position:relative;
	font-size:16px;
	line-height:1.7em;
	margin-bottom:25px;
	color:rgba(0,0,10,0.45);
}

.about-section-two.style-two{
	padding-top:150px;
}

/*** 

====================================================================
	Services Section Three
====================================================================

 ***/

.services-section-three{
	position:relative;
	padding-top:120px;
	padding-bottom:80px;
}

.services-section-three:before{
	position:absolute;
	content:'';
	left:0px;
	top:100px;
	width:120%;
	height:650px;
	-ms-transform: skewY(-10deg);
    -webkit-transform: skewY(-10deg);
    transform: skewY(-10deg);
	background:#f7fafc url(/images/resource/side-img.png) 90% bottom no-repeat;
}

.services-section-three .auto-container{
	position:relative;
}

.services-section-three .section-count{
    position: absolute;
    left:-120px;
    bottom: 40%;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: left top;
}

.services-section-three .section-count .count{
    position: relative;
    font-size: 53px;
    font-weight: 300;
    line-height: 1em;
    color: rgba(0,0,10,0.17);
}

.services-section-three .section-count:after{
	position: absolute;
    content: '';
    left: 80px;
    top: 25px;
    height: 250px;
    width: 2px;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: left top;
    background-color: #1a1a1a;
}

.services-block-four{
	position:relative;
	margin-bottom:40px;
}

.services-block-four .inner-box{
	position:relative;
	z-index:10;
	background-color:#ffffff;
	padding:70px 20px 60px 45px;
	-webkit-box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
	-moz-box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
	box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
}

.services-block-four .inner-box .icon-box{
	position:relative;
	color:#ff5151;
	font-size:50px;
	line-height:1em;
	margin-bottom:10px;
}

.services-block-four .inner-box .post-title{
	position:relative;
	font-size:16px;
	font-weight:400;
	margin-bottom:14px;
	margin-top:18px;
	color:rgba(9,9,9,0.50);
}

.services-block-four .inner-box h3{
	position:relative;
	font-size:20px;
	font-weight:500;
	line-height:1.6em;
	margin-bottom:15px;
}

.services-block-four .inner-box h3 a{
	color:#090913;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.services-block-four .inner-box h3 a:hover{
	color:#ff5151;
}

.services-block-four .inner-box .text{
	position:relative;
	font-size:15px;
	font-weight:400;
	color:rgba(9,9,9,0.50);
}

.services-block-four .inner-box .read-more{
	position:relative;
	color:#090913;
	font-weight:500;
	font-size:15px;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.services-block-four .inner-box .read-more:hover{
	color:#ff5151;
}

.services-block-four.style-two{
	margin-bottom:90px;
}

.services-block-four.style-two .inner-box{
	padding:0px;
	background:none;
	box-shadow:none;
}

.services-section-three .services-text{
	position:relative;
	margin-top:60px;
}

.services-section-three .services-text h2{
	position:relative;
	color:#00000a;
	font-size:38px;
	font-weight:500;
	line-height:1.3em;
	margin-bottom:20px;
}

.services-section-three .services-text .btn-column{
	text-align:right;
}

/*** 

====================================================================
	Case Services
====================================================================

 ***/
 
.case-section{
	 position:relative;
	 padding-top:200px;
	 padding-bottom:100px;
}

.case-section .auto-container{
	position:relative;
}

.case-section .section-count{
    position: absolute;
    right:0px;
    top: -10%;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: left top;
}

.case-section .section-count .count{
    position: relative;
    font-size: 53px;
    font-weight: 300;
    line-height: 1em;
    color: rgba(0,0,10,0.17);
}

.case-section .section-count:after{
	position: absolute;
    content: '';
    left: 80px;
    top: 25px;
    height: 220px;
    width: 2px;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: left top;
    background-color: #1a1a1a;
}

.case-section .image-column{
	 position:relative;
	 margin-bottom:40px;
}

.case-section .image-column .image{
	 position:relative;
}

.case-section .image-column .image img{
	 position:relative;
	 width:100%;
	 display:block;
}

.case-section .image-column .image .big-letter{
	 position:absolute;
	 right:0px;
	 top:40%;
	 color:#f6f8f9;
	 font-size:300px;
	 font-weight:700;
	 letter-spacing:1px;
}

.case-section .content-column{
	position:relative;
}

.case-section .content-column .sec-title-two{
	position:relative;
	margin-bottom:30px;
}

.case-section .content-column .text{
	position:relative;
	font-size:16px;
	margin-top:0px;
	line-height:1.8em;
	margin-bottom:80px;
	color:rgba(0,0,0,0.65);
}

.case-section .content-column .percentage-bar{
	position:relative;
}

.case-section .content-column .percentage-bar .percentage{
	position:relative;
	margin-bottom:40px;
}

.case-section .content-column .percentage-bar .percentage .inner{
	position:relative;
	padding-left:68px;
}

.case-section .content-column .percentage-bar .percentage .inner .big-letter{
	position:absolute;
	left:0px;
	top:-12px;
	color:#e5e5e6;
	font-size:90px;
	line-height:1em;
	font-weight:400;
	text-transform:uppercase;
}

.case-section .content-column .percentage-bar .percentage .inner .total{
	position:relative;
	color:#00000a;
	font-size:36px;
	font-weight:600;
}

.case-section .content-column .percentage-bar .percentage .inner .total .arrow{
	position:relative;
	color:#1be65e;
	font-size:26px;
	line-height:1em;
	font-weight:300;
}

.case-section .content-column .percentage-bar .percentage .inner .total .arrow.down{
	color:#ff4052;
}

.case-section .content-column .percentage-bar .percentage .inner .percent-text{
	position:relative;
	color:#00000a;
	font-size:18px;
	font-weight:300;
	margin-top:10px;
	text-transform:capitalize;
}

/*** 

====================================================================
	Testimonial Services Three
====================================================================

 ***/

.testimonial-section-three{
	position:relative;
	padding-top:100px;
	padding-bottom:40px;
}

.testimonial-section-three .auto-container{
	position:relative;
}

.testimonial-section-three .section-count{
    position: absolute;
    left:-120px;
    bottom: 0%;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: left top;
}

.testimonial-section-three .section-count .count{
    position: relative;
    font-size: 53px;
    font-weight: 300;
    line-height: 1em;
    color: rgba(0,0,10,0.17);
}

.testimonial-section-three .section-count:after{
	position: absolute;
    content: '';
    left: 80px;
    top: 25px;
    height: 250px;
    width: 2px;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: left top;
    background-color: #1a1a1a;
}

.testimonial-section-three:before{
    position: absolute;
    content: '';
    left: 0px;
    top: 100px;
    width: 120%;
    height: 720px;
    -ms-transform: skewY(-10deg);
    -webkit-transform: skewY(-10deg);
    transform: skewY(-10deg);
    background: #f7fafc url(/images/resource/side-img.png) 90% bottom no-repeat;
}

.testimonial-section-three .map-pattern{
    position: absolute;
	left:0px;
	top:140px;
    content: '';
    width: 100%;
    height: 100%;
    background:url(/images/resource/map-pattern.png) center bottom no-repeat;
}

.testimonial-section-three .owl-carousel .owl-stage-outer{
	padding-top:20px;
}

.testimonial-block-two{
	position:relative;
}

.testimonial-block-two .inner-box{
	position:relative;
	padding:50px 50px;
	background-color:#ffffff;
	-webkit-box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
	-moz-box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
	box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
}

.testimonial-block-two .inner-box .author{
	position:relative;
	padding-left:80px;
}

.testimonial-block-two .inner-box .author .image{
	position:absolute;
	left:0px;
	top:-6px;
	width:60px;
	height:60px;
	display:block;
	overflow:hidden;
	border-radius:50%;
}

.testimonial-block-two .inner-box .author h3{
	position:relative;
	color:#000000;
	font-size:18px;
	font-weight:500;
	line-height:1em;
	margin-bottom:4px;
	text-transform:uppercase;
}

.testimonial-block-two .inner-box .author .designation{
	position:relative;
	color:#a2a2a2;
	font-size:16px;
}

.testimonial-block-two .inner-box .text{
	position:relative;
	font-size:16px;
	line-height:1.7em;
	color:rgba(0,0,0,0.60);
	margin-top:35px;
}

.testimonial-section-three .owl-nav{
	display:none;
}

.testimonial-section-three .owl-dots{
	position:relative;
	text-align:right;
	margin-top:40px;
}

.testimonial-section-three .owl-dots .owl-dot{
	position:relative;
	margin-left:10px;
	display:inline-block;
}

.testimonial-section-three .owl-dots .owl-dot span{
	position:relative;
	display:block;
	width:8px;
	height:8px;
	border-radius:50%;
	background-color:#d5d5d5;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.testimonial-section-three .owl-dots .owl-dot.active span,
.testimonial-section-three .owl-dots .owl-dot:hover span{
	background:#ff5151;
}

/*** 

====================================================================
	Sponsors Section Two
====================================================================

 ***/
 
.sponsors-section-two{
	position:relative;
	padding-bottom:200px;
}

.sponsors-section-two h2{
	position:relative;
	color:#00000a;
	font-size:38px;
	font-weight:500;
	line-height:1.2em;
	margin-bottom:30px;
}

.sponsors-section-two .carousel-outer{
	padding-right:140px;
}

.sponsors-section-two .image-box a img{
	opacity:0.50;
	width:auto;
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.sponsors-section-two .image-box a img:hover{
	opacity:0.90;
}

.sponsors-section-two .owl-dots,
.sponsors-section-two .owl-nav{
	display:none;
}

/*** 

====================================================================
	Project Section
====================================================================

 ***/

.project-tab-section{
	position:relative;
	padding-top:150px;
	padding-bottom:80px;
}

.project-tab-section .auto-container{
	position:relative;
}

.project-tab-section .sec-title-two{
	margin-bottom:0px;
}

.project-tab-section .section-count{
    position: absolute;
    right:0px;
    top: 0px;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: left top;
}

.project-tab-section .section-count .count{
    position: relative;
    font-size: 53px;
    font-weight: 300;
    line-height: 1em;
    color: rgba(0,0,10,0.17);
}

.project-tab-section .section-count:after{
	position: absolute;
    content: '';
    left: 80px;
    top: 25px;
    height: 250px;
    width: 2px;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: left top;
    background-color: #1a1a1a;
}

.project-tab-section .big-letter{
	position:relative;
	color:#f6f8f9;
	font-size:360px;
	font-weight:700;
	line-height:0.7em;
}

.project-tab{
	position:relative;	
}

.project-tab .see-all{
	position:relative;
	color:#00000a;
	font-size:15px;
	font-weight:600;
	padding-bottom:4px;
	top:-15px;
	display:inline-block;
	text-transform:uppercase;
	border-bottom:2px solid #00000a;
}

.project-tab .project-carousel .owl-nav,
.project-tab .project-carousel .owl-dots{
	display:none;
}

.project-section .three-item-carousel .owl-dots,
.project-section .three-item-carousel .owl-nav{
	display:none;
}

.project-tab .tabs-header{
	position:relative;
	margin-left:170px;
	margin-top:-15px;
	margin-bottom:80px;
}

.project-tab .product-tab-btns{
	position:relative;
}

.project-tab .product-tab-btns .p-tab-btn{
	position:relative;
	line-height:24px;
	padding:0px 0px;
	cursor:pointer;
	color:#b4b4b4;
	font-weight:500;
	font-size:18px;
	display:inline-block;
	margin-bottom:10px;
	margin-right:28px;
	text-transform:capitalize;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.project-tab .product-tab-btns .p-tab-btn:last-child{
	margin-right:0px !important;
}

.project-tab .product-tab-btns .p-tab-btn:hover,
.project-tab .product-tab-btns .p-tab-btn.active-btn{
	color:#000000;
}

.project-tab .p-tabs-content{
	position:relative;
	display:block;
	padding-left:30px;
	padding-right:30px;
}

.project-tab .p-tab{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:auto;
	visibility:hidden;
	opacity:0;	
	background-color:#ffffff;
	-webkit-transform:translateY(30px);
	-ms-transform:translateY(30px);
	-o-transform:translateY(30px);
	-moz-transform:translateY(30px);
	transform:translateY(30px);
}

.project-tab .p-tab.active-tab{
	position:relative;
	visibility:visible;
	z-index:5;
	opacity:1;
	-webkit-transform:translateY(0px);
	-ms-transform:translateY(0px);
	-o-transform:translateY(0px);
	-moz-transform:translateY(0px);
	transform:translateY(0px);
	transition:all 0.7s ease;
	-moz-transition:all 0.7s ease;
	-webkit-transition:all 0.7s ease;
	-ms-transition:all 0.7s ease;
	-o-transition:all 0.7s ease;
}

.project-tab.style-two .tabs-header{
	margin-top:0px;
	margin-left:0px;
	margin-bottom:50px;
}

.project-tab.style-two .product-tab-btns .p-tab-btn{
	margin-right:20px;
}

.project-tab.style-two .p-tabs-content{
	padding:0px;
}

.project-tab.style-two .owl-dots{
	display:none;
}

.project-tab.style-two .owl-nav{
    position: absolute;
    left: 0px;
    top: 50%;
    width: 100%;
    margin-top: -25px;
}

.project-tab.style-two .owl-nav .owl-prev,
.project-tab.style-two .owl-nav .owl-next{
	display:inline-block;
	color:#eeeeee;
	font-size:32px;
	width:50px;
	height:50px;
	text-align:center;
	line-height:48px;
	border:1px solid #eeeeee;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
}

.project-tab.style-two .owl-nav .owl-prev:hover,
.project-tab.style-two .owl-nav .owl-next:hover{
	color:#ff4052;
	border-color:#ff4052;
}

.project-tab.style-two .owl-nav .owl-prev{
	position:absolute;
	left:20px;
}

.project-tab.style-two .owl-nav .owl-next{
	position:absolute;
	right:20px;
}

/*Gallery Item*/

.gallery-item{
	position:relative;
}

.gallery-item .inner-box{
	position:relative;
}

.gallery-item .inner-box .image{
	position:relative;
}

.gallery-item .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.gallery-item .inner-box .image .overlay-box{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	opacity:0;
	text-align:center;
	transition:all 0.6s ease;
	-moz-transition:all 0.6s ease;
	-webkit-transition:all 0.6s ease;
	-ms-transition:all 0.6s ease;
	-o-transition:all 0.6s ease;
	background-color:rgba(255,81,81,0.80);
}

.gallery-item .inner-box .image .overlay-box .content{
	position:relative;
	width:100%;
	height:100%;
}

.gallery-item .inner-box .image .overlay-box .content a{
	position:relative;
	top:45%;
	display:inline-block;
}

.gallery-item .inner-box .image .overlay-box .content a .icon{
	position:relative;
	width:40px;
	height:40px;
	color:#8d8d8d;
	font-size:15px;
	border-radius:50%;
	line-height:40px;
	margin:0px 4px;
	display:inline-block;
	background-color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.gallery-item .inner-box .image .overlay-box .content .icon:hover{
	color:#ffffff;
	background-color:#ff5151;
}

.gallery-item .inner-box:hover .overlay-box{
	opacity:1;
}

/*** 

====================================================================
	Team Section
====================================================================

 ***/
 
.team-section-two{
	position:relative;
	padding-top:100px;
}

.team-section-two .auto-container{
	position:relative;
}

.team-section-two .section-count{
    position: absolute;
    left:-120px;
    top: 42%;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: left top;
}

.team-section-two .section-count .count{
    position: relative;
    font-size: 53px;
    font-weight: 300;
    line-height: 1em;
    color: rgba(0,0,10,0.17);
}

.team-section-two .section-count:after{
	position: absolute;
    content: '';
    left: 80px;
    top: 25px;
    height: 250px;
    width: 2px;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: left top;
    background-color: #1a1a1a;
}

.team-section-two .sec-title-two .big-letter{
	position:absolute;
	right:0px;
	top:0px;
	color:#f6f8f9;
	font-size:300px;
	font-weight:700;
	line-height:0.6em;
}

.team-section-two:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	right:0px;
	bottom:150px;
	display:block;
	background-image: -ms-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: -moz-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: -o-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FFFFFF), color-stop(100, #f7fafc));
	background-image: -webkit-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: linear-gradient(to bottom, #FFFFFF 0%, #f7fafc 100%);
}

/*Team Block Two*/

.team-block-two{
	position:relative;
}

.team-block-two .inner-box{
	position:relative;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.team-block-two .inner-box:hover{
	-webkit-box-shadow: 0px 0px 30px 0px rgba(204,204,204,0.40);
	-moz-box-shadow: 0px 0px 30px 0px rgba(204,204,204,0.40);
	box-shadow: 0px 0px 30px 0px rgba(204,204,204,0.40);
}

.team-block-two .inner-box .image{
	position:relative;
}

.team-block-two .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.team-block-two .inner-box .lower-content{
	position:relative;
	padding:45px 0px;
	text-align:center;
}

.team-block-two .inner-box .lower-content h3{
	position:relative;
	font-size:20px;
	font-weight:500;
	text-transform:capitalize;
}

.team-block-two .inner-box .lower-content h3 a{
	color:#00000a;
}

.team-block-two .inner-box .lower-content .designation{
	position:relative;
	color:#aeaeae;
	font-size:16px;
}

/*** 

====================================================================
	Contact Section
====================================================================

 ***/
 
.contact-section{
	position:relative;
	padding-top: 320px;
    padding-bottom: 150px;
}

.contact-section .auto-container{
	position:relative;
}

.contact-section .map-pattern{
	position: absolute;
    left: 0px;
    top: 100px;
    content: '';
    width: 100%;
    height: 100%;
    background: url(/images/resource/map-pattern-1.png) right center no-repeat;
}

.contact-section:before{
	position: absolute;
    content: '';
    left: 0px;
    top: 100px;
    width: 120%;
    height: 720px;
    -ms-transform: skewY(-10deg);
    -webkit-transform: skewY(-10deg);
    transform: skewY(-10deg);
    background: #ff5151 -22% bottom no-repeat;
}

.contact-section .section-count{
    position: absolute;
    right:0px;
    top: 0px;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: left top;
}

.contact-section .section-count .count{
    position: relative;
    font-size: 53px;
    font-weight: 300;
    line-height: 1em;
    color:#ffffff;
}

.contact-section .section-count:after{
	position: absolute;
    content: '';
    left: 80px;
    top: 25px;
    height: 250px;
    width: 2px;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: left top;
    background-color: #f3f3f3;
}

.contact-section .form-column{
	position:relative;
	margin-bottom:40px;
	z-index:1;
}

.contact-section .form-column .column-inner{
	position:relative;
}

.contact-section.style-two{
	padding-top:200px;
	padding-bottom:0px;
}

.contact-section.alternate{
	padding-top:120px;
	padding-bottom:85px;
}

.contact-section.padding-top{
	padding-top:70px;
}

.contact-section.alternate:before,
.contact-section.style-two:before{
	display:none;
}

.contact-section.alternate:after{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-color:rgba(0,0,0,0.50);
}

.contact-section.style-two .form-group input,
.contact-section.style-two .form-group textarea{
	color:#000000 !important;
}

.contact-section.style-two .form-group input::-webkit-input-placeholder,
.contact-section.style-two .form-group textarea::-webkit-input-placeholder{
	color: #000000;
}

.contact-section.style-two .form-group input::-moz-placeholder {
 color: #000000;
}
.contact-section.style-two .form-group input:-moz-placeholder {
  color: #000000;
}
.contact-section.style-two .form-group textarea::-moz-placeholder {
  color: #000000;
}

.contact-section.style-two .info-column .inner h2{
	color:#00000a;
	font-weight:600;
}

.contact-section.style-two .info-column .inner .text{
	color:#8c8c8c;
}

.contact-section.style-two .info-column .inner h3{
	color:#00000a;
}

/*** 

====================================================================
	Contact Form
====================================================================

 ***/

.contact-form{
	margin-top:10px;
}

.contact-form .form-group{
	position:relative;
	margin-bottom:30px;
}

.contact-form .form-group input[type="text"],
.contact-form .form-group input[type="email"]{
	position:relative;
	display:block;
	width:100%;
	line-height:24px;
	padding:0px 0px 8px;
	border-bottom:2px solid #f3f3f3;
	height:46px;
	font-size:16px;
	background:none;
	color:#ffffff;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.contact-form .form-group input[type="text"]:focus,
.contact-form .form-group input[type="email"]:focus,
.contact-form .form-group textarea:focus{
	border-color:#d0d0d0;
}

.contact-form .form-group textarea{
	position:relative;
	display:block;
	width:100%;
	line-height:24px;
	padding:0px 0px;
	height:160px;
	background:none;
	resize:none;
	font-size:18px;
	color:#ffffff;
	border-bottom:2px solid #f3f3f3;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.contact-form .form-group input::-webkit-input-placeholder {
  color: #ffffff;
}
.contact-form .form-group input:-ms-input-placeholder {
  color: #ffffff;
}
.contact-form .form-group input::-moz-placeholder {
  color: #ffffff;
}
.contact-form .form-group input:-moz-placeholder {
  color: #ffffff;
}
.contact-form .form-group textarea::-moz-placeholder {
  color: #ffffff;
}
.contact-form .form-group textarea::-webkit-input-placeholder{
	color: #ffffff;
}

.contact-form button{
	position:relative;
	margin-top:5px;
}

.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error{
	border-color:#ff0000 !important;	
}

.contact-form label.error{
	line-height:24px;
	padding:5px 0px 0px;
	margin:0px;
	text-transform:uppercase;
	font-size:11px;
	color:#ff0000;
	font-weight:500;	
}

.contact-section .info-column{
	position:relative;
}

.contact-section .info-column .inner{
	position:relative;
	padding-left:50px;
}

.contact-section .info-column .inner h2{
	position:relative;
	color:#ffffff;
	font-size:38px;
	font-weight:500;
	line-height:1.4em;
	margin-bottom:25px;
}

.contact-section .info-column .inner .text{
	position:relative;
	color:#ffffff;
	font-size:20px;
	font-weight:300;
}

.contact-section .info-column .inner h3{
	position:relative;
	font-size:28px;
	font-weight:500;
	color:#ffffff;
	margin-top:5px;
}

.contact-section .content-column .inner{
	position:relative;
    padding-left: 50px;
	margin-bottom:30px;
	z-index:1;
}

.contact-section .content-column .inner .text{
	position: relative;
    color:#8c8c8c;
    font-size: 20px;
    font-weight: 300;
}

.contact-section .content-column .inner h3{
	position: relative;
    font-size: 28px;
    font-weight: 500;
    color:#00000a;
    margin-top: 5px;
}

.contact-section .content-column.light .text,
.contact-section .content-column.light h3{
	color:#ffffff;
}

/*** 

====================================================================
	Services Section Four
====================================================================

 ***/

.services-section-four{
	position:relative;
	padding:160px 0px 80px;
}

.services-section-four .services-title-section{
	position:relative;
	padding-bottom:80px;
}

.services-section-four .services-title-section h2{
	position:relative;
	color:#00000a;
	font-size:38px;
	font-weight:600;
	line-height:1.4em;
	margin-bottom:30px;
}

.services-section-four .services-title-section .text{
	position:relative;
	font-size:16px;
	line-height:1.9em;
	color:rgba(0,0,0,0.65);
}

.services-section-four .services-title-section .learn-more{
	position:relative;
	margin-top:40px;
	color:#00000a;
	font-size:16px;
	font-weight:500;
	display:inline-block;
}

.services-block-five{
	position:relative;
	margin-bottom:40px;
}

.services-block-five .inner-box{
	position:relative;
}

.services-block-five .inner-box .icon-box{
	position:relative;
	color:#8075f2;
	font-size:64px;
	line-height:1em;
	margin-bottom:20px;
}

.services-block-five .inner-box h3{
	position:relative;
	font-size:20px;
	font-weight:500;
	line-height:1.4em;
	margin-bottom:15px;
}

.services-block-five .inner-box h3 a{
	position:relative;
	color:#090913;
}

.services-block-five .inner-box .text{
	position:relative;
	font-size:15px;
	line-height:1.8em;
	color:rgba(9,9,19,0.60);
}

/*** 

====================================================================
	Fluid Section One
====================================================================

***/

.fluid-section-one{
	position:relative;
	padding:0px;
	background:url(/images/resource/side-img.png) 115% top no-repeat;
}

.fluid-section-one .outer-container{
	position:relative;
}

.fluid-section-one .image-column{
	position:absolute;
	float:left;
	left:0px;
	top:0px;
	width:50%;
	height:100%;
	margin-bottom:40px;
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center top;
}

.fluid-section-one .image-column .image-box{
	position:relative;
	display:none;	
}

.fluid-section-one .image-column .image-box img{
	position:relative;
	display:block;
	width:100%;
}

.fluid-section-one .text-column{
	position:relative;
	float:right;
	width:50%;
}

.fluid-section-one .text-column .inner{
	position:relative;
	max-width:600px;
	padding:0px 15px 0px 70px;
}

.fluid-section-one .text-column .text{
	position:relative;
	line-height:1.8em;
	margin-bottom:60px;
	font-size:18px;
	color:rgba(0,0,10,0.65);
}

.fluid-section-one .percentage-bar{
	position:relative;
}

.fluid-section-one .percentage-bar .percentage{
	position:relative;
	margin-bottom:30px;
}

.fluid-section-one .percentage-bar .percentage .percent-inner{
	position:relative;
}

.fluid-section-one .percentage-bar .percentage .percent-inner .total{
	position:relative;
	color:#00000a;
	font-size:36px;
	font-weight:600;
}

.fluid-section-one .percentage-bar .percentage .percent-inner .total .arrow{
	position:relative;
	color:#1be65e;
	font-size:26px;
	line-height:1em;
	font-weight:300;
}

.fluid-section-one .percentage-bar .percentage .percent-inner .total .arrow.down{
	color:#ff4052;
}

.fluid-section-one .percentage-bar .percentage .percent-inner .percent-text{
	position:relative;
	color:#00000a;
	font-size:18px;
	font-weight:300;
	margin-top:10px;
	text-transform:capitalize;
}

.fluid-section-one.style-two{
	padding:150px 0;
	background:none;
}

.fluid-section-one.style-two .image-column{
	background-size:contain;
}

.fluid-section-one.style-two .image-column .big-letter{
	position:absolute;
	right:-40px;
	top:-100px;
	font-size:300px;
	font-weight:700;
	line-height:1em;
	color:rgba(9,9,19,0.03);
}

.fluid-section-one.style-two .text-column .text{
	margin-bottom:30px;
}

.fluid-section-one.style-two .learn-more{
	position:relative;
	color:#000000;
	font-weight:600;
	font-size:16px;
}

/*Gallery Section*/

.gallery-section{
	position:relative;
	padding-top:120px;
}

.gallery-section .sec-title-three{
	margin-bottom:100px;
}

.gallery-section .sec-title-three .theme-btn,
.gallery-section .sec-title-four .theme-btn{
	border-radius:0px;
	margin-top:105px;
}

.gallery-section .owl-dots,
.gallery-section .owl-nav{
	display:none;
}

.gallery-block{
	position:relative;
}

.gallery-block .inner-box{
	position:relative;
}

.gallery-block .inner-box .image{
	position:relative;
}

.gallery-block .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.gallery-block .inner-box .image .overlay-box{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-color:rgba(0,0,0,0.50);
}

.gallery-block .inner-box .image .overlay-box .content{
	position:absolute;
	left:0px;
	bottom:0px;
	width:100%;
	padding:30px 20px 30px 40px;
	border-top:1px solid rgba(255,255,255,0.10);
}

.gallery-block .inner-box .image .overlay-box .content h3{
	position:relative;
	font-weight:600;
	font-size:20px;
	line-height:1.4em;
	text-transform:capitalize;
}

.gallery-block .inner-box .image .overlay-box .content h3 a{
	color:#ffffff;
}

/*** 

====================================================================
	Testimonial Section Two
====================================================================

***/

.testimonial-section-four{
	position:relative;
	padding:165px 0px 90px;
}

.testimonial-section-four .owl-dots,
.testimonial-section-four .owl-nav{
	display:none;
}

.testimonial-block-three{
	position:relative;
}

.testimonial-block-three .inner-box{
	position:relative;
	display:inline-block;
}

.testimonial-block-three .inner-box .logo{
	position:relative;
	margin-bottom:20px;
}

.testimonial-block-three .inner-box .logo img{
	position:relative;
	width:auto;
}

.testimonial-block-three .inner-box .text{
	position:relative;
	font-size:16px;
	line-height:1.8em;
	margin-bottom:40px;
	color:rgba(0,0,0,0.80);
}

.testimonial-block-three .inner-box .author-info{
	position:relative;
}

.testimonial-block-three .inner-box .author-info .author-inner{
	position:relative;
	padding-left:80px;
	min-height:50px;
}

.testimonial-block-three .inner-box .author-info .author-inner .image{
	position:absolute;
	left:0px;
	top:0px;
	width:50px;
	height:50px;
	overflow:hidden;
	border-radius:50px;
}

.testimonial-block-three .inner-box .author-info .author-inner h3{
	position:relative;
	color:#000000;
	font-size:18px;
	font-weight:600;
	line-height:1em;
	margin-bottom:3px;
}

.testimonial-block-three .inner-box .author-info .author-inner .designation{
	position:relative;
	color:#a2a2a2;
	font-size:16px;
}

.testimonial-block-three .inner-box .author-info .quote-icon{
	position:absolute;
	right:30px;
	bottom:0px;
	color:#f5f5f5;
	font-size:50px;
	line-height:1em;
}

/*** 

====================================================================
	Sponsors Section Three
====================================================================

 ***/

.sponsors-section-three{
	position:relative;
	padding:35px 0px;
	border-top:1px solid #f0f0f0;
	border-bottom:1px solid #f0f0f0;
}

.sponsors-section-three .image-box{
	position:relative;
	text-align:center;
}

.sponsors-section-three .image-box a{
	position:relative;
	display:block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.sponsors-section-three .image-box a img{
	width:auto;
	opacity:0.7;
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.sponsors-section-three .image-box:hover a img{
	opacity:0.9;
}

.sponsors-section-three .owl-nav,
.sponsors-section-three .owl-dots{
	display:none;
}

/*** 

====================================================================
	Project Section Two
====================================================================

 ***/

.project-section-two{
	position:relative;
	padding:195px 0px 190px;
}

.project-section-two .carousel-column{
	position:relative;
	margin-bottom:40px;
}

.project-section-two .carousel-column .inner-column{
	position:relative;
	padding-right:80px;
}

.project-block{
	position:relative;
}

.project-block .inner-box{
	position:relative;
}

.project-block .inner-box h3{
	position:relative;
	color:#00000a;
	font-size:28px;
	font-weight:600;
	margin-bottom:20px;
}

.project-block .inner-box .text{
	position:relative;
	font-size:16px;
	line-height:1.8em;
	margin-bottom:40px;
	color:rgba(0,0,0,0.65);
}

.project-block .inner-box .theme-btn{
	border-radius:0px;
	z-index:90;
	overflow:hidden;
}

.project-section-two .carousel-column .owl-nav{
	display:none;
}

.project-section-two .carousel-column .owl-dots{
	position:relative;
	text-align:right;
	margin-top:-30px;
}

.project-section-two .carousel-column .owl-dots .owl-dot{
	position:relative;
	display:inline-block;
	margin-left:6px;	
}

.project-section-two .carousel-column .owl-dots .owl-dot span{
	position:relative;
	display:block;
	width:10px;
	height:10px;
	margin:0px;
	background:#f1f1f1;
	border-radius:50%;
	transition:all 500ms ease;
}

.project-section-two .carousel-column .owl-dots .owl-dot:hover span,
.project-section-two .carousel-column .owl-dots .owl-dot.active span{
	background:#8075f2;
}

/*** 

====================================================================
	Experiance Section
====================================================================

 ***/

.experiance-section{
	position:relative;
	padding:135px 0px 110px;
	background-color:#4a2eb3;
}

.experiance-section.alternate{
	background-color:#253c6f;
}

.services-block-six{
	position:relative;
	margin-bottom:50px;
}

.services-block-six .inner-box{
	position:relative;
	text-align:center;
}

.services-block-six .inner-box .icon-box:after{
	position: absolute;
    content: '';
    top: 30px;
    width: 75%;
    margin-left: 30px;
    border: 1px dashed #ffffff;
}

.services-block-six:last-child .inner-box .icon-box:after{
	display:none;
}

.services-block-six .inner-box .icon-box{
	position:relative;
	color:#ffffff;
	font-size:56px;
	line-height:1em;
	margin-bottom:20px;
}

.services-block-six .inner-box h3{
	position:relative;
	font-weight:500;
	font-size:20px;
	margin-bottom:15px;
}

.services-block-six .inner-box h3 a{
	color:#ffffff;
}

.services-block-six .inner-box .text{
	position:relative;
	color:#ffffff;
	font-size:16px;
	line-height:1.8em;
}

/*** 

====================================================================
	Services Section Five
====================================================================

 ***/

.services-section-five{
	position:relative;
	padding:150px 0px 120px;
}

.services-section-five h2{
	position:relative;
	color:#00000a;
	font-size:38px;
	line-height:1.4em;
	text-align:center;
	font-weight:600;
	margin-bottom:15px;
}

.services-section-five .text{
	position:relative;
	text-align:center;
	font-size:18px;
	margin-bottom:100px;
	color:rgba(0,0,10,0.65);
}

.services-block-seven{
	position:relative;
	margin-bottom:40px;
}

.services-block-seven .inner-box{
	position:relative;
}

.services-block-seven .inner-box .icon-box{
	position:relative;
	color:#008df9;
	font-size:60px;
	line-height:1em;
	margin-bottom:20px;
}

.services-block-seven .inner-box h3{
	position:relative;
	font-size:20px;
	font-weight:500;
	margin-bottom:15px;
	line-height:1.4em;
}

.services-block-seven .inner-box h3 a{
	color:#00000a;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.services-block-seven .inner-box h3 a:hover{
	color:#008df9;
}

.services-block-seven .inner-box .inner-text{
	position:relative;
	font-size:15px;
	color:rgba(0,0,10,0.60);
}

/*** 

====================================================================
	Services Section Six
====================================================================

 ***/
 
.services-section-six{
	position:relative;
	padding:145px 0px;
	margin-top:90px;
	background-color:#fcfdff;
}

.services-section-six .sec-title-four .theme-btn{
	border-radius:0px;
	margin-top:70px;
}

.services-block-eight{
	position:relative;
	margin-bottom:30px;
}

.services-block-eight .inner-box{
	position:relative;
	padding:50px 60px;
	background-color:#ffffff;
	-webkit-box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
	-moz-box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
	box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
}

.services-block-eight .inner-box:before{
	position:absolute;
	content:'';
	right:0px;
	bottom:0px;
	border-top: 30px solid #ebf3f8;
	border-right: 30px solid transparent;
}

.services-block-eight .inner-box:after{
	position:absolute;
	content:'';
	right:0px;
	bottom:0px;
	border-bottom: 30px solid #fafbff;
	border-left: 30px solid transparent;
}

.services-block-eight .inner-box .content{
	position:relative;
	padding-left:90px;
}

.services-block-eight .inner-box .content .icon-box{
	position:absolute;
	left:0px;
	top:0px;
	font-size:50px;
	color:#6e7994;
	line-height:1em;
	margin-bottom:20px;
}

.services-block-eight .inner-box .content h3{
	position:relative;
	color:#090913;
	font-size:20px;
	font-weight:500;
	line-height:1.4em;
	margin-bottom:10px;
}

.services-block-eight .inner-box .content h3 a{
	position:relative;
	color:#090913;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.services-block-eight .inner-box .content h3 a:hover{
	color:#008df9;
}

.services-block-eight .inner-box .content .text{
	position:relative;
	font-size:15px;
	line-height:1.8em;
	color:rgba(9,9,19,0.60);
}

/*** 

====================================================================
	Services Section seven
====================================================================

 ***/
 
.services-section-seven{
	position:relative;
	padding:100px 0px 80px;
	background:url(/images/resource/map-pattern-2.png) center 25% no-repeat;
}

.services-section-seven:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	display:block;
	z-index:-1;
	background-image: -ms-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: -moz-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: -o-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FFFFFF), color-stop(100, #f7fafc));
	background-image: -webkit-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: linear-gradient(to bottom, #FFFFFF 0%, #f7fafc 100%);
}

.services-section-seven .title-box{
	position:relative;
	margin-bottom:70px;
}

.services-section-seven .title-box .big-letter{
	position:absolute;
	right:105%;
	top:80px;
	font-weight:700;
	font-size:300px;
	color:rgba(9,9,19,0.03);
}

.services-section-seven .title-box .title-column{
	position:relative;
}

.services-section-seven .title-box .title-column h2{
	position:relative;
	color:#00000a;
	font-size:38px;
	font-weight:600;
	line-height:1.3em;
	margin-bottom:40px;
}

.services-section-seven .title-box .title-column .text{
	position:relative;
}

.services-section-seven .title-box .title-column .text p{
	position:relative;
	font-size:16px;
	line-height:1.8em;
	margin-bottom:20px;
	color:rgba(0,0,10,0.65);
}

/*Services Block Nine*/

.services-block-nine{
	position:relative;
	margin-bottom:40px;
}

.services-block-nine .inner-box{
	position:relative;
	padding:80px 0px;
	text-align:center;
	background-color:#ffffff;
	-webkit-box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
	-moz-box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
	box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
}

.services-block-nine .inner-box .icon-box{
	position:relative;
	color:#00d541;
	font-size:64px;
	line-height:1em;
	margin-bottom:25px;
}

.services-block-nine .inner-box h3{
	position:relative;
	font-size:20px;
	font-weight:600;
	margin-bottom:5px;
	text-transform:capitalize;
}

.services-block-nine .inner-box h3 a{
	position:relative;
	color:#090913;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.services-block-nine .inner-box h3 a:hover{
	color:#00d541;
}

.services-block-nine .inner-box .designation{
	position:relative;
	font-size:16px;
	font-weight:500;
	color:rgba(9,9,19,0.40);
}

/*** 

====================================================================
	Recent Work Section
====================================================================

 ***/

.recent-work-section{
	position:relative;
	padding-top:100px;
}

.recent-work-section .sec-title-five .big-letter{
	position:absolute;
	right:100%;
	top:80px;
	font-size:300px;
	font-weight:700;
	color:rgba(9,9,19,0.03);
}

.recent-work-section  .btn-style-one{
	border-radius:0px;
	margin-top:110px;
	float:right;
}

.recent-work-section .outer-container{
	padding-left:30px;
	padding-right:30px;
}

.recent-work-section .outer-container .owl-dots,
.recent-work-section .outer-container .owl-nav{
	display:none;
}

/*** 

====================================================================
 Pricing Section
====================================================================

***/

.pricing-section-two{
	position:relative;
	padding:250px 0px 120px;
	z-index:5;
}

.pricing-section-two .content-column{
	position:relative;
	margin-bottom:80px; 
}

.pricing-section-two .content-column .sec-title{
	margin-bottom:50px;
}

.pricing-section .content-column .sec-title .subtitle{
	margin-bottom:10px;
}

.pricing-section-two .content-column .sec-title h2{
	font-size:36px;
	line-height:1.5em; 
}

.pricing-section-two .content-column .inner{
	position:relative;
	padding-right:70px;
}

.pricing-section-two .content-column .text{
	line-height:1.8em;
	font-size:15px;
	color:#00000a;
	margin-bottom:40px;
}

.pricing-tabs .tab-btns{
	position:relative;
	display:inline-block;
	border-radius:26px;
	overflow:hidden; 
}

.pricing-tabs .tab-btns .tab-btn{
	position:relative;
	float:left;
	font-size:16px;
	color:#00000a;
	min-width:140px;
	font-weight:500;
	text-transform:capitalize;
	line-height:30px;
	padding:11px 36px;
	border:1px solid #d0d0d0;
	cursor:pointer; 
}

.pricing-tabs .tab-btns .tab-btn:first-child{
	border-radius:25px 0px 0px 25px; 
}

.pricing-tabs .tab-btns .tab-btn:last-child{
	border-right:0px;
	border-radius:0px 25px 25px 0px; 
}

.pricing-tabs .tab-btns .active-btn{
	background:#00d541;
	color:#ffffff;
	border-color:#00d541;
}

.pricing-section-two .image-column{
	position:relative;
	margin-bottom:30px; 
}

.pricing-section-two .image-column .inner{
	position:relative;
	margin-top:-50px;
	padding-right:60px;
}

.pricing-section-two .image-column .inner .big-letter{
	position: absolute;
    right:-20px;
    top: 10px;
    font-size: 300px;
    font-weight: 600;
    color: rgba(9,9,19,0.03);
}

.pricing-section-two .image-column .out-box{
	position:absolute;
	top:0px;
	left:0px;
	width:1200px;
	height:650px;
	overflow:hidden;
	border-radius:300px;
	-webkit-transform:rotate(-25deg) translate(50px,-150px);
	-ms-transform:rotate(-25deg) translate(50px,-150px);
	-o-transform:rotate(-25deg) translate(50px,-150px);
	-moz-transform:rotate(-25deg) translate(50px,-150px);
	transform:rotate(-25deg) translate(50px,-150px);
}

.pricing-section-two .image-column .out-box .image-box{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:-100px -100px;
	-webkit-transform:rotate(25deg) scale(1.5,1.5);
	-ms-transform:rotate(25deg) scale(1.5,1.5);
	-o-transform:rotate(25deg) scale(1.5,1.5);
	-moz-transform:rotate(25deg) scale(1.5,1.5);
	transform:rotate(25deg) scale(1.5,1.5); 
}

.pricing-section-two .pricing-tabs .pr-content{
	position:relative; 
	float:right;
}

.pricing-section-two .price-column{
	position:relative;
	width:370px;
}

.pricing-section-two .pr-content .pr-tab{
	position:absolute;
	left:0px;
	top:0px;
	z-index:1;
	transition:all 0.7s ease;
	-moz-transition:all 0.7s ease;
	-webkit-transition:all 0.7s ease;
	-ms-transition:all 0.7s ease;
	-o-transition:all 0.7s ease;
	transform:scale(0.8,0.8) translateX(-220px);
}

.pricing-section-two .pr-content .pr-tab.active-tab{
	position:relative;
	z-index:5;
	transform:scale(1) translateX(0); 
}

.pricing-section-two .price-column .col-inner{
	position:relative;
	padding:60px 30px;
	text-align:center;
	background:#ffffff;
	border-radius:2px;
	-webkit-box-shadow:0px 0px 20px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:0px 0px 20px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:0px 0px 20px 1px rgba(0,0,0,0.05);
	-o-box-shadow:0px 0px 20px 1px rgba(0,0,0,0.05);
	box-shadow:0px 0px 20px 1px rgba(0,0,0,0.05); 
}

.pricing-section-two .price-column .plan-title{
	position:relative;
	font-size:18px;
	font-weight:600;
	color:#00000a;
	text-transform:uppercase;
	letter-spacing:1px;
	line-height:1.8em; 
}

.pricing-section-two .price-column .duration{
	position:relative;
	font-size:18px;
	font-weight:400;
	color:#b1b1b1;
	text-transform:capitalize;
	line-height:1.8em;
}

.pricing-section-two .price-column .price{
	position:relative;
	font-size:60px;
	font-weight:700;
	letter-spacing:2px;
	color:#00000a;
	text-transform:uppercase;
	line-height:1.8em;
	padding:10px 0px 20px;
}

.pricing-section-two .price-column .price sup{
	top:-10px; 
}

.pricing-section-two .price-column .specs-list{
	position:relative;
	padding-bottom:20px;
}

.pricing-section-two .price-column .specs-list li{
	position:relative;
	line-height:24px;
	font-size:15px;
	font-weight:500;
	margin-bottom:12px;
}

/*** 

====================================================================
	Fluid Section Two
====================================================================

***/

.fluid-section-two{
	position:relative;
	padding-bottom:150px;
	background:url(/images/resource/side-img.png) 115% top no-repeat;
}

.fluid-section-two .outer-container{
	position:relative;
}

.fluid-section-two .image-column{
	position:absolute;
	float:right;
	right:0px;
	top:0px;
	width:50%;
	height:100%;
	margin-bottom:40px;
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center top;
}

.fluid-section-two .image-column .image-box{
	position:relative;
	display:none;	
}

.fluid-section-two .image-column .image-box img{
	position:relative;
	display:block;
	width:100%;
	background-size:contain;
}

.fluid-section-two .carousel-column{
	position:relative;
	float:left;
	width:50%;
	margin-bottom:25px;
}

.fluid-section-two .carousel-column .owl-nav{
	display:none;
}

.fluid-section-two.no-bg{
	background:none;
}

.fluid-section-two .carousel-column .owl-dots{
	position:relative;
	text-align:right;
	margin-top:-30px;
}

.fluid-section-two .carousel-column .owl-dots .owl-dot{
	position:relative;
	display:inline-block;
	margin-left:6px;	
}

.fluid-section-two .carousel-column .owl-dots .owl-dot span{
	position:relative;
	display:block;
	width:10px;
	height:10px;
	margin:0px;
	background:#f1f1f1;
	border-radius:50%;
	transition:all 500ms ease;
}

.fluid-section-two .carousel-column .owl-dots .owl-dot:hover span,
.fluid-section-two .carousel-column .owl-dots .owl-dot.active span{
	background:#00d541;
}








.fluid-section-two .carousel-column .inner{
	position:relative;
	max-width:600px;
	width:100%;
	float:right;
	padding:0px 15px 0px 15px;
}

.fluid-section-two .carousel-column .inner .sec-title-five h2{
	font-size:36px;
}

.fluid-section-two .carousel-column .inner .text{
	max-width:460px;
}

/*** 

====================================================================
	Experiance Section Two
====================================================================

 ***/

.experiance-section-two{
	position:relative;
	padding:135px 0px 130px;
	background:#16cc45 url(/images/resource/map-pattern.png) center center no-repeat;
}

.experiance-section-two .big-work{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	text-align:center;
	font-size:180px;
	font-weight:700;
	color:rgba(255,255,255,0.10);
}

.services-block-ten{
	position:relative;
	margin-bottom:30px;
}

.services-block-ten .inner-box{
	position:relative;
	text-align:center;
	padding:40px 0px;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.services-block-ten .inner-box:hover{
	background-color:#ffffff;
}

.services-block-ten .inner-box .icon-box{
	position:relative;
	color:#ffffff;
	font-size:56px;
	line-height:1em;
	margin-bottom:20px;
}

.services-block-ten .inner-box h3{
	position:relative;
	font-weight:500;
	font-size:20px;
	margin-bottom:15px;
}

.services-block-ten .inner-box h3 a{
	color:#ffffff;
}

.services-block-ten .inner-box .text{
	position:relative;
	color:#ffffff;
	font-size:16px;
	line-height:1.8em;
}

.services-block-ten .inner-box:hover .icon-box{
	color:#16cc45;
}

.services-block-ten .inner-box:hover h3 a{
	color:#000000;
}

.services-block-ten .inner-box:hover .text{
	color:rgba(0,0,10,0.65);
}

/*** 

====================================================================
	Page Title Style
====================================================================

***/

.page-title{
	position:relative;
	padding:150px 0 50px;
	background-size:cover;
	background-position:center top;
	background-repeat:no-repeat;
}

/*.page-title:before{*/
/*	position:absolute;*/
/*	content:'';*/
/*	left:0px;*/
/*	top:0px;*/
/*	width:100%;*/
/*	height:100%;*/
/*	display:block;*/
/*	background-color:rgba(0,0,0,0.3);*/
/*}*/

.page-title h1{
	position:relative;
	font-size:60px;
	line-height:1.2em;
	font-weight:600;
	letter-spacing:1px;
	color:#ffffff;
	margin-bottom:20px;
	text-transform:capitalize;
}

.page-title .bread-crumb:before{
	position:absolute;
	content:'';
	left:0px;
	top:12px;
	height:2px;
	width:85px;
	background-color:rgba(255,255,255,1);
}

.page-title .bread-crumb{
	position:relative;
	padding-left:110px;
}

.page-title .bread-crumb li{
	position:relative;
	display:inline-block;
	line-height:30px;
	margin-left:20px;
	color:#ffffff;
	font-size:16px;
	font-weight:500;
	text-transform:uppercase;
}

.page-title .bread-crumb li:before{
	content:'/';
	position:absolute;
	right:-20px;
	top:1px;
	width:20px;
	font-size:14px;
	text-align:center;
	line-height:30px;
}

.page-title .bread-crumb li:first-child{
	margin-left:0px;	
}

.page-title .bread-crumb li:last-child:before{
	display:none;	
}

.page-title .bread-crumb li a{
	color:#ffffff;
	font-size:16px;
	font-weight:500;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.page-title .bread-crumb li a:hover{
	color:#008df9;
}

.page-title .text{
	position:relative;
	color:#ffffff;
	font-size:22px;
	margin-top:40px;
}

.page-title .centered-title{
	position:relative;
	text-align:center;
	margin-bottom:150px;
}

.about-blocks-outer{
	position:relative;
	padding:120px 0px 100px;
}

/*About Block*/

.about-block{
	position:relative;
	margin-bottom:50px;
}

.about-block .inner-box{
	position:relative;
}

.about-block .inner-box .image-column{
	position:relative;
	margin-bottom:40px;
}

.about-block .inner-box .image-column .inner-column{
	position:relative;
	padding-left:170px;
}

.about-block .inner-box .image-column .inner-column h2{
	position:absolute;
	left:0px;
	top:50px;
	color:#00000a;
	font-size:16px;
	font-weight:600;
	letter-spacing:1px;
	text-transform:uppercase;
}

.about-block .inner-box .image-column .inner-column h2:after{
	position:absolute;
	content:'';
	left:120%;
	top:10px;
	width:70px;
	height:2px;
	z-index:1;
	background-color:#00000a;
}

.about-block .inner-box .image-column .image{
	position:relative;
}

.about-block .inner-box .image-column .image img{
	position:relative;
	width:100%;
	display:block;
}

.about-block .inner-box .content-column{
	position:relative;
}

.about-block .inner-box .content-column .content-inner{
	position:relative;
	padding-left:80px;
}

.about-block .inner-box .content-column .content-inner .text{
	position:relative;
	font-size:16px;
	line-height:1.8em;
	color:rgba(0,0,10,0.60);
}

/*** 

====================================================================
	Services Section Eight
====================================================================

***/

.services-section-eight{
	position:relative;
	padding:100px 0 80px;
}

.services-section-eight:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	display:block;
	z-index:-1;
	background-image: -ms-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: -moz-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: -o-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FFFFFF), color-stop(100, #f7fafc));
	background-image: -webkit-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: linear-gradient(to bottom, #FFFFFF 0%, #f7fafc 100%);
}

.services-section-eight .big-letter{
	position:absolute;
	content:'';
	right:0px;
	top:40px;
	font-size:300px;
	font-weight:700;
	color:#f6f8f9;
}

.services-section-eight .outer-blocks{
	position:relative;
	margin-top:90px;
}

.services-section-eight.alternate{
	padding-bottom:80px;
}

.services-section-eight.alternate:before{
	display:none;
}

.services-block-eleven{
	position:relative;
	margin-bottom:30px;
}

.services-block-eleven .inner-box{
	position:relative;
	text-align:center;
	padding:30px 15px 30px;
	background-color:#ffffff;
	-webkit-box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
	-moz-box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
	box-shadow: 0px 0px 18px 11px rgba(251,251,251,1);
}

.services-block-eleven .inner-box .icon-box{
	position:relative;
	color:#47515e;
	font-size:62px;
	line-height:1em;
	margin-bottom:25px;
}

.services-block-eleven .inner-box h3{
	position:relative;
	font-size:20px;
	font-weight:600;
	margin-bottom:14px;
	text-transform:capitalize;
}

.services-block-eleven .inner-box h3 a{
	position:relative;
	color:#090913;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.services-block-eleven .inner-box h3 a:hover{
	color:#ff4052;
}

.services-block-eleven .inner-box .text{
	position:relative;
	font-size:15px;
	line-height:1.8em;
	color:rgba(9,9,19,0.60);
}

/*Services Block Twelve*/

.services-block-twelve{
	position:relative;
	margin-bottom:70px;
}

.services-block-twelve .inner-box{
	position:relative;
}

.services-block-twelve .inner-box .image{
	position:relative;
}

.services-block-twelve .inner-box .image a:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	z-index:1;
	display:block;
	background-color:rgba(0,0,0,0.40);
}

.services-block-twelve .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.services-block-twelve .inner-box .lower-box{
	position:relative;
	padding-top:25px;
}

.services-block-twelve .inner-box .lower-box h3{
	position:relative;
	font-weight:600;
	font-size:20px;
}

.services-block-twelve .inner-box .lower-box h3 a{
	position:relative;
	color:#090913;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.services-block-twelve .inner-box .lower-box h3 a:hover{
	color:#ff4052;
}

/*** 

====================================================================
	MixitUp Gallery Section
====================================================================

***/

.work-gallery-section{
	position:relative;
	padding:160px 0px 0px;
}

.work-gallery-section .auto-container{
	position:relative;
}

.work-gallery-section .big-letter{
	position:absolute;
	right:0px;
	top:0px;
	color:#f6f8f9;
	font-size:300px;
	font-weight:700;
	line-height:0.7em;
}

.mixitup-gallery .filters,
.masonry-section .filters{
	margin-bottom:80px;
}

.work-gallery-section.full-width .filter-list{
	padding-left:30px;
	padding-right:30px;
}

.work-gallery-section .filters .filter-tabs{
	position:relative;
}

.work-gallery-section .filters li{
	position: relative;
    display: inline-block;
    color: #b4b4b4;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
	margin-bottom:15px;
	margin-right:30px;
    text-transform: capitalize;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.work-gallery-section .filters .filter.active,
.work-gallery-section .filters .filter:hover{
    color: #000000;
}

.gallery-item.mix{
	display:none;
}

.work-gallery-section .gallery-item{
	margin-bottom:30px;
}

.sortable-masonry .gallery-item{
    display: block;
}

.work-gallery-section.full-width-two .filter-list{
	padding:0px;
}

.work-gallery-section.full-width-two .gallery-item{
	margin-bottom:0px;
	padding:0px;
}

.work-gallery-section .sortable-masonry.style-two .gallery-item{
	margin-bottom:6px;
	padding:0px 3px;
}

.work-gallery-section .sortable-masonry.style-two .big-column{
	width:42%;
	float:left;
}

.work-gallery-section .sortable-masonry.style-two .medium-column{
	width:29%;
	float:left;
}

.work-gallery-section .sortable-masonry.style-two .small-column{
	width:21%;
	float:left;
}

.work-gallery-section.full-width-three .gallery-item{
	width:20%;
	float:left;
	margin-bottom:0px;
}

/*** 

====================================================================
	Blog Section
====================================================================

***/

.blog-section,
.editor {
	position:relative;
	padding:100px 0 50px;
}

.blog-grid .row{
	margin:0px -35px;
}

.blog-grid .news-block{
	padding:0px 35px;
}

.news-block{
	position:relative;
	margin-bottom:70px;
}

.news-block .inner-box{
	position:relative;
}

.news-block .inner-box .image{
	position:relative;
}

.news-block .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.news-block .inner-box .lower-box{
	position:relative;
	padding-top:35px;
}

.news-block .inner-box .lower-box .post-info{
	position:relative;
	font-size:16px;
	margin-bottom:8px;
	font-weight:500;
	color:rgba(0,0,0,0.40);
	text-transform:uppercase;
}

.news-block .inner-box .lower-box h3{
	position:relative;
	color:#000000;
	font-size:24px;
	font-weight:600;
	margin-bottom:10px;
	line-height:1.4em;
}

.blog-grid-fullwidth .news-block .inner-box .lower-box h3{
	min-height:57px;	
}

.news-block .inner-box .lower-box h3 a{
	position:relative;
	color:#000000;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.news-block .inner-box .lower-box h3 a:hover{
	color:#008df9;
}

.news-block .inner-box .lower-box .text{
	position:relative;
	font-size:18px;
	line-height:1.8em;
	margin-bottom:20px;
	color:rgba(0,0,0,0.60);
}

.news-block .inner-box .lower-box .more{
	position:relative;
	color:#00aaf9;
	font-size:18px;
	font-weight:500;
}

.news-block .inner-box .lower-box .more .arrow{
	position:relative;
	top:2px;
	margin-left:5px;
}

/*** 

====================================================================
	Styled Pagination
====================================================================

***/

.styled-pagination{
	position:relative;
}

.styled-pagination ul{
	position:relative;
	display:inline-block;	
}

.styled-pagination li{
	position:relative;
	display:block;
	float:left;
	margin:0px 10px 10px 0px;
}

.styled-pagination li a{
	position:relative;
	line-height:44px;
	height:45px;
	font-size:22px;
	min-width:45px;
	background:#ffffff;
	color:#000000;
	font-weight:500;
	text-align:center;
	border-radius:50%;
	display:inline-block;
	border:2px solid #e5e5e5;
	transition:all 500ms ease;
	text-transform:capitalize;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
}

.styled-pagination li a:hover,
.styled-pagination li a.active{
	color:#00aaf9;
	border-color:#00aaf9;
}

.styled-pagination li a.next{
	border:0px;
	color:#b2b2b2;
}

.blog-grid-fullwidth .outer-container{
	position:relative;
	padding:0px 40px;
}

.blog-grid-fullwidth .news-block .inner-box h3{
	font-size:20px;
}

/*** 

====================================================================
	Blog Single Section
====================================================================

***/

.editor .blog-detail{
	position:relative;
}

.editor .blog-detail h2{
	position:relative;
	color:#1a1a1a;
	font-size:38px;
	font-weight:500;
	margin-bottom:30px;
}

.editor .blog-detail .inner-box{
	position:relative;
	margin-bottom:30px;
}

.editor .blog-detail .inner-box p{
	position:relative;
	font-size:16px;
	line-height:1.8em;
	margin-bottom:15px;
	color:rgba(0,0,0,0.65);
	word-break: break-all;
}

.editor .blog-detail .inner-box p:last-child{
	margin-bottom:0px;
}

.editor .blog-detail .inner-box blockquote{
	position:relative;
	padding:60px 50px;
	border:0px;
	margin-top:50px;
	margin-bottom:70px;
	background: linear-gradient(to bottom right,#020525,#005a7f);
}

.editor .blog-detail .inner-box blockquote .blockquote-text{
	position:relative;
	color:#fff;
	font-size:24px;
	font-weight:500;
	line-height:1.4em;
}

.editor .blog-detail .inner-box .two-column{
	position:relative;
}

.editor .blog-detail .inner-box .two-column .image{
	position:relative;
	margin-bottom:40px;
}

.editor .blog-detail .inner-box .two-column .image img{
	position:relative;
	width:100%;
	display:block;
}

.editor .blog-detail .inner-box .dark-text{
	position:relative;
	color:#000000;
	font-size:18px;
	font-weight:500;
	margin-bottom:30px;
}

/*post share options*/

.editor .blog-detail .inner-box .post-share-options{
	position:relative;
	padding:10px 0px 0px;
}

.editor .blog-detail .inner-box .post-share-options .tags span{
	color:#000000;
}

.editor .blog-detail .inner-box .post-share-options .tags a{
	position:relative;
	color:#9a9a9a;
	font-size:16px;
	font-weight:400;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.editor .blog-detail .inner-box .post-share-options .tags a:hover{
	color:#008df9;
}

.editor .blog-detail .inner-box .post-share-options .tags{
	position:relative;
	color:#b7b7b7;
	font-size:16px;
	font-weight:700;
	margin-bottom:8px;
}

/*Author Box*/

.blog-author-box{
	position:relative;
	padding:75px 0px;
	margin-top:140px;
	margin-bottom:120px;
	background-color:#f5f5f5;
}

.blog-author-box .author-inner{
	position:relative;
	padding-left:225px;
}

.blog-author-box .author-inner .image{
	position:absolute;
	left:0px;
	top:0px;
	width:175px;
	height:175px;
	border-radius:50%;
	overflow:hidden;
	margin-bottom:20px;
}

.blog-author-box .author-inner h3{
	position:relative;
	color:#000000;
	font-size:22px;
	font-weight:500;
	margin-bottom:10px;
}

.blog-author-box .author-inner{
	position:relative;
	font-size:16px;
	line-height:1.8em;
	margin-bottom:15px;
	color:rgba(0,0,0,0.45);
}

.blog-author-box .author-inner .view-profile{
	position:relative;
	color:#000000;
	font-size:15px;
	font-weight:600;
	text-transform:uppercase;
	padding-bottom:4px;
	border-bottom:2px solid #000000;
}

/*** 

====================================================================
	Comments Area
====================================================================

 ***/

.editor .comments-area{
	position:relative;
}

.editor .group-title{
	position:relative;
	margin-bottom:60px;
}

.editor .group-title h2{
	position:relative;
	font-size:42px;
	color:#000000;
	font-weight:600;
	padding-bottom:0px;
	text-transform:capitalize;
}

.editor .comments-area .comment-box{
	position:relative;
	padding:0px 0px 50px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.editor .comments-area .comment-box.reply-message{
	padding-left:100px;
}

.editor .comments-area .comment{
	position:relative;
	font-size:14px;
	padding:0px 0px 0px 100px;
}

.editor .comments-area .comment .comment-inner{
	position:relative;
}

.editor .comments-area .comment .comment-reply{
	position:absolute;
	right:0px;
	top:0px;
	color:#000000;
	font-size:16px;
	font-weight:500;
	display:inline-block;
	text-transform:uppercase;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.editor .comments-area .comment-box .author-thumb{
	position:absolute;
	left:0px;
	top:0px;
	margin-bottom:20px;
}

.editor .comments-area .comment-box .author-thumb img{
	width:75px;
	display:block;
	border-radius:50%;
}

.editor .comments-area .comment-info{
	color:#3e5773;
	line-height:24px;
	font-size:13px;	
}

.editor .comments-area .comment-box strong{
	font-size:18px;
	font-weight:600;
	color:#000000;
	line-height:16px;
	text-transform:capitalize;
}

.editor .comments-area .comment-box{
	color:rgba(0,0,0,0.65);
	font-size:18px;
    margin-top:8px;
	margin-bottom:0px;
    line-height: 1.7em;
}

.editor .comments-area .comment-info .comment-time{
	position:relative;
	font-size:15px;
	color:#a5a5a5;
	margin-top:6px;
}

.editor .comments-area .comment-box .theme-btn{
	padding:5px 30px;
	font-size:12px;
	border:1px  solid #fbca00;
	text-transform:uppercase;
	letter-spacing:1px;
}

/*Blog Comment Form*/

.blog-comment-form{
	margin-top:100px;
}

.blog-comment-form .title-box{
	position:relative;
}

.blog-comment-form .title-box h2{
	position:relative;
	color:#000000;
	font-size:42px;
	font-weight:600;
	line-height:1.2em;
	margin-bottom:15px;
}

.blog-comment-form .title-box{
	position:relative;
	font-size:16px;
	line-height:1.7em;
	margin-bottom:40px;
	color:rgba(0,0,0,0.65);
}

.blog-comment-form .form-group{
	position:relative;
	margin-bottom:40px;
}

.blog-comment-form .form-group input[type="text"],
.blog-comment-form .form-group input[type="email"]{
	position:relative;
	display:block;
	width:100%;
	line-height:24px;
	padding:0px 0px 8px;
	color:#000000;
	border-bottom:2px solid #000000;
	height:46px;
	font-size:16px;
	background:none;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.blog-comment-form .form-group input[type="text"]:focus,
.blog-comment-form .form-group input[type="email"]:focus,
.blog-comment-form .form-group textarea:focus{
	border-color:#ff4052;
}

.blog-comment-form .form-group textarea{
	position:relative;
	display:block;
	width:100%;
	line-height:24px;
	padding:0px 0px;
	color:#000000;
	height:160px;
	background:none;
	resize:none;
	font-size:16px;
	border-bottom:2px solid #000000;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.blog-comment-form button{
	position:relative;
	margin-top:5px;
}

/*** 

====================================================================
	Project Single
====================================================================

 ***/

.project-single{
	position:relative;
	padding-top:165px;
}

.project-single .image{
	position:relative;
	margin-bottom:90px;
}

.project-single .image img{
	position:relative;
	width:100%;
	display:block;
}

.project-single .project-title{
	position:relative;
}

.project-single .project-title .info-column{
	position:relative;
	margin-bottom:30px;
}

.project-single .project-title .info-column h3{
	position:relative;
	color:#171717;
	font-weight:500;
	font-size:18px;
	text-transform:uppercase;
}

.project-single .project-title .info-column .text{
	position:relative;
	color:#b2b2b2;
	font-size:18px;
}

.project-single .project-title .btn-column{
	position:relative;
	text-align:right;
}

.project-single .project-title .btn-column .theme-btn{
	border-color:#000000;
	border-radius:0px;
}

.project-single .project-title .btn-column .theme-btn:hover{
	background-color:#000000;
}

.project-single .inner-box .bold-text{
	position:relative;
	color:#212121;
	font-size:16px;
	margin-top:50px;
	margin-bottom:30px;
	line-height:1.7em;
	font-weight:500;
}

.project-single .inner-box .text{
	position:relative;
}

.project-single .inner-box .text p{
	position:relative;
	font-size:16px;
	line-height:1.7em;
	margin-bottom:20px;
	color:rgba(0,0,10,0.65);
}

.gallery-carousel-section .owl-dots,
.gallery-carousel-section .owl-nav{
	display:none;
}

.project-single .project-title-box{
	position:relative;
	margin:80px 0px 60px;
}

.project-single .project-title-box h2{
	position:relative;
	color:#151515;
	font-size:36px;
	font-weight:600;
	line-height:1.4em;
	margin-bottom:40px;
}

.project-single .project-title-box .text{
	position:relative;
	color:#212121;
	font-size:18px;
	line-height:1.8em;
}

.project-column-info{
	position:relative;
	padding-bottom:80px;
	border-bottom:1px solid #ededed;
}

.project-column-info h2{
	position:relative;
	color:#151515;
	font-size:36px;
	font-weight:600;
	margin-bottom:20px;
}

.project-column-info .text{
	position:relative;
	font-size:16px;
	line-height:1.7em;
	max-width:500px;
	margin-bottom:40px;
	color:rgba(25,24,24,0.65);
}

/*** 

====================================================================
	Team Section Three
====================================================================

 ***/

.team-section-three{
	position:relative;
	padding-top:200px;
}

.team-section-three .big-letter{
    position: absolute;
    content: '';
    right: 0px;
    top: 40px;
    font-size: 300px;
    font-weight: 700;
    color: #f6f8f9;
}

.team-section-three.fullwidth-team .team-block-four,
.team-section-three.fullwidth-team .team-block-four .inner-box .image{
	padding:0px;
	margin-bottom:0px;
}

.team-section-three .team-outer-blocks{
	position:relative;
	margin-top:140px;
}

.team-section-three .team-outer-blocks .team-block-two{
	margin-bottom:60px;
}

.team-block-three{
	position:relative;
	margin-bottom:80px;
}

.team-block-three .inner-box{
	position:relative;
	text-align:center;
}

.team-block-three .inner-box .image{
	position:relative;
	width:270px;
	height:270px;
	border-radius:50%;
	overflow:hidden;
	margin:0 auto;
	margin-bottom:25px;
}

.team-block-three .inner-box h3{
	position:relative;
	color:#00000a;
	font-size:22px;
	font-weight:600;
}

.team-block-three .inner-box .designation{
	position:relative;
	color:#959595;
	font-size:16px;
}

.team-section-three.alternate:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	display:block;
	z-index:-1;
	background-image: -ms-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: -moz-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: -o-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FFFFFF), color-stop(100, #f7fafc));
	background-image: -webkit-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: linear-gradient(to bottom, #FFFFFF 0%, #f7fafc 100%);
}

/*Team Block Four*/

.team-block-four{
	position:relative;
	margin-bottom:70px;
}

.team-block-four .inner-box{
	position:relative;
	text-align:center;
}

.team-block-four .inner-box .image{
	position:relative;
	margin-bottom:25px;
}

.team-block-four .inner-box .image img{
	width:100%;
	display:block;
}

.team-block-four .inner-box .lower-box{
	position:relative;
	text-align:center;
	padding-bottom:25px;
	border-bottom:1px solid #f0f0f0;
}

.team-block-four .inner-box h3{
	position:relative;
	color:#00000a;
	font-size:20px;
	font-weight:600;
}

.team-block-four .inner-box .designation{
	position:relative;
	color:#959595;
	font-size:16px;
}

/*** 

====================================================================
	Price Section
====================================================================

 ***/

.price-page-section{
	position:relative;
	padding-top:200px;
}

.price-page-section .big-letter{
	position: absolute;
    content: '';
    right: 0px;
    top: 40px;
    font-size: 300px;
    font-weight: 700;
    color: #f6f8f9;
}

.price-page-section .pricing-outer-blocks{
	margin-top:140px;
}

.price-page-section .pricing-outer-blocks.style-two{
	margin-top:-230px;
}

/*Price Block Two*/

.price-block-two{
	position:relative;
	margin-bottom:40px;
	transition:all 0.7s ease;
	-moz-transition:all 0.7s ease;
	-webkit-transition:all 0.7s ease;
	-ms-transition:all 0.7s ease;
	-o-transition:all 0.7s ease;
}

.price-block-two .inner-box{
	position:relative;
	padding:70px 30px 70px;
	text-align:center;
	background:#ffffff;
	-webkit-box-shadow:0px 0px 20px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:0px 0px 20px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:0px 0px 20px 1px rgba(0,0,0,0.05);
	-o-box-shadow:0px 0px 20px 1px rgba(0,0,0,0.05);
	box-shadow:0px 0px 20px 1px rgba(0,0,0,0.05); 
}

.price-block-two .inner-box .plan-title{
	position:relative;
	font-size:18px;
	font-weight:600;
	color:#00000a;
	text-transform:uppercase;
	letter-spacing:1px;
	line-height:1.8em; 
}

.price-block-two .inner-box .duration{
	position:relative;
	font-size:18px;
	font-weight:400;
	color:#b1b1b1;
	text-transform:capitalize;
	line-height:1.8em;
}

.price-block-two .inner-box .price{
	position:relative;
	font-size:60px;
	font-weight:700;
	letter-spacing:2px;
	color:#00000a;
	text-transform:uppercase;
	line-height:1.8em;
	padding:10px 0px 20px;
}

.price-block-two .inner-box .price sup{
	top:-10px; 
}

.price-block-two .inner-box .specs-list{
	position:relative;
	padding-bottom:20px;
}

.price-block-two .inner-box .specs-list li{
	position:relative;
	line-height:24px;
	font-size:15px;
	font-weight:500;
	margin-bottom:12px;
}

.price-block-two .inner-box .theme-btn{
	border-radius:0px;
	letter-spacing:1px;
}

.price-block-two .inner-box .theme-btn:hover{
	opacity:1;
	border-color:#000000;
	background-color:#000000;
}

.price-page-section.alternate{
	padding-bottom:120px;
}

.price-page-section.alternate:before{
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: block;
    z-index: -1;
    background-image: -ms-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
    background-image: -moz-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
    background-image: -o-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FFFFFF), color-stop(100, #f7fafc));
    background-image: -webkit-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
    background-image: linear-gradient(to bottom, #FFFFFF 0%, #f7fafc 100%);
}

.price-page-section.style-two{
	padding-top:0px;
}

/*** 

====================================================================
	Contact Info Section
====================================================================

 ***/

.contact-info-section{
	position:relative;
	padding:160px 0px 90px;
}

.contact-info-section .sec-title-four{
	margin-bottom:80px;
}

.contact-info-block{
	position:relative;
	margin-bottom:30px;
}

.contact-info-block .inner{
	position:relative;
	text-align:center;
}

.contact-info-block .inner .icon-box{
	position:relative;
	color:#00000a;
	font-size:52px;
	line-height:1em;
	margin-bottom:15px;
}

.contact-info-block .inner h3{
	position:relative;
	font-size:24px;
	font-weight:500;
	color:#00000a;
	margin-bottom:8px;
}

.contact-info-block .inner .text{
	position:relative;
	font-size:16px;
	line-height:1.8em;
	color:rgba(0,0,0,0.65);
}

.contact-info-block .inner .social-icon-two{
	margin-top:10px;
}

.contact-info-block .inner .social-icon-two li a{
	color:#d8d8d8;
}

.contact-info-block .inner .social-icon-two li a:hover{
	color:#000000;
}

/*** 

====================================================================
	Contact FullWidth Section
====================================================================

 ***/

.contact-fullwidth-section{
	position:relative;
}

.contact-fullwidth-section .outer-container{
	position:relative;
	background-color:#232323;
}

.contact-fullwidth-section .outer-container .map-column{
	position:absolute;
	left:0px;
	top:0px;
	width:50%;
	height:100%;
	display:block;
	float:left;
}

.contact-fullwidth-section .outer-container .form-column{
	position:relative;
	right:0px;
	top:0px;
	width:50%;
	height:100%;
	display:block;
	float:right;
}

.contact-fullwidth-section .outer-container .form-column .inner-column{
	position:relative;
	padding:100px 75px 60px;
}

.contact-fullwidth-section .outer-container .form-column .inner-column h2{
	position:relative;
	color:#ffffff;
	font-size:38px;
	font-weight:600;
	line-height:1.2em;
	margin-bottom:40px;
}

/*** 

====================================================================
	Map Section Style
====================================================================

***/

.map-data{
	text-align:center;
	font-size:14px;
	font-weight:400;
	line-height:1.8em;
}

.map-data h6{
	font-size:16px;
	font-weight:700;
	text-align:center;
	margin-bottom:5px;
	color:#121212;
}

.contact-fullwidth-section .outer-container .map-column .map-canvas{
	position: absolute !important;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}

.map-section .map-canvas{
	position:relative;
	height:650px;
}

.map-section .map-canvas.style-two{
	height:560px;
}




/*** 

====================================================================
	Faq Section
====================================================================

 ***/

.faq-section{
	position:relative;
	overflow:hidden;
	padding:160px 0px 160px;
}

.faq-section:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-image: -ms-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: -moz-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: -o-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FFFFFF), color-stop(100, #f7fafc));
	background-image: -webkit-linear-gradient(top, #FFFFFF 0%, #f7fafc 100%);
	background-image: linear-gradient(to bottom, #FFFFFF 0%, #f7fafc 100%);
}

.faq-section .faq-title{
	position:relative;
	margin-bottom:60px;
}

.faq-section .faq-title h2{
	position:relative;
	color:#000000;
	font-size:30px;
	font-weight:600;
	margin-bottom:15px;
	line-height:1.2em;
}

.faq-section .faq-title .title-text{
	position:relative;
	font-size:17px;
	margin-bottom:40px;
	color:rgba(0,0,0,0.70);
}


/*Sidebar Search Form*/

.faq-section .faq-search-box{
	position:relative;
	margin-top:30px;
    width: 100%;
	margin-bottom:20px;
}

.faq-section .faq-search-box .form-group{
	position:relative;
	margin:0px;	
}

.faq-section .faq-search-box .form-group input[type="text"],
.faq-section .faq-search-box .form-group input[type="search"]{
	position:relative;
	line-height:28px;
	color:#222222;
	display:block;
	font-size:16px;
	width:100%;
	height:60px;
	padding:15px 15px 15px 20px;
	border:1px solid #ebebeb;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.faq-section .faq-search-box .form-group button{
	position:absolute;
	right:0px;
	top:0px;
	width:65px;
	height:60px;
	line-height:58px;
	text-align:center;
	display:block;
	font-size:22px;
	color:#c0c0c0;
	background:none;
	font-weight:normal;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.faq-section .faq-search-box .form-group input:focus{
	border-color:#ff4052;	
}

/*** 

====================================================================
	Faq Form Section
====================================================================

***/

.faq-form-section{
	position:relative;
	padding:100px 0px 0px;
}

.faq-form-section h2{
	position:relative;
	color:#161616;
	font-size:30px;
	font-weight:600;
	line-height:1.2em;
	margin-bottom:50px;
}

.faq-form-section .form-group{
	position:relative;
	margin-bottom:30px;
}

.faq-form-section .form-group input[type="text"],
.faq-form-section .form-group input[type="email"],
.faq-form-section .form-group select{
	position:relative;
	display:block;
	width:100%;
	line-height:24px;
	padding:0px 0px 8px;
	color:#000000;
	border-bottom:2px solid #e5e5e5;
	height:46px;
	font-size:16px;
	background:none;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.faq-form-section .form-group input[type="text"]:focus,
.faq-form-section .form-group input[type="email"]:focus,
.faq-form-section .form-group textarea:focus{
	border-color:#ff4052;
}

.faq-form-section .form-group textarea{
	position:relative;
	display:block;
	width:100%;
	line-height:24px;
	padding:0px 0px;
	color:#000000;
	height:160px;
	background:none;
	resize:none;
	font-size:16px;
	border-bottom:2px solid #e5e5e5;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

/*** 

====================================================================
	Error Section Style
====================================================================

***/

.error-section{
	position:relative;
	text-align:center;
	padding:300px 0px 250px;
}

.error-section:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-color:rgba(0,0,0,0.50);
}

.error-section .error-big-text{
	position: relative;
	font-size:200px;
	color:#ffffff;
	font-weight:600;
	letter-spacing:5px;
	margin-bottom:20px;
	line-height:1em;
}

.error-section h2{
	position:relative;
	color:#ffffff;
	font-weight:400;
	font-size:38px;
	line-height:1.3em;
	margin-bottom:15px;
}

.error-section .text{
	position:relative;
	font-size:18px;
	font-weight:300;
	margin-bottom:30px;
	color:#ffffff;
}

.error-section .error-options{
	position:relative;
}

.error-section .error-options .or{
	position:relative;
	color:#ffffff;
	font-size:18px;
	margin:0px 25px;
	font-weight:500;
}

.error-section .btn-style-six{
	padding:12px 25px;
}

.error-search-box{
	position:relative;
	margin-top:12px;
	width:320px;
	display:inline-block;
}

.error-search-box .form-group{
	position:relative;
	margin:0px;	
}

.error-search-box .form-group input[type="text"],
.error-search-box .form-group input[type="search"]{
	position:relative;
	line-height:32px;
	padding:10px 50px 10px 20px;
	border:1px solid #ebeaea;
	display:block;
	font-size:14px;
	width:100%;
	height:55px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.error-search-box .form-group button{
	position:absolute;
	right:0px;
	top:0px;
	height:55px;
	width:50px;
	line-height:52px;
	text-align:center;
	display:block;
	font-size:18px;
	color:#c0c0c0;
	background:none;
	border-radius:0px 3px 3px 0px;
	font-weight:normal;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.error-search-box .form-group input:focus + button,
.error-search-box .form-group button:hover{
	color:#ff4052;
}